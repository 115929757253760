import React, { useEffect, useMemo, useState } from "react";
import Article5Wrapper from "./style";
import Facebook from "../../../assets/images/fblogowhite.svg";
import Instagram from "../../../assets/images/lilogowhite.svg";
import WhatsApp from "../../../assets/images/whatsapplogowhite.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  imgBaseURL,
  maxContentAd,
  horizontalSmallAdHeight,
} from "../../../helper/constants";
import moment from "moment";
import ReadMoreSection from "../ReadMoreSection";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { Helmet } from "react-helmet";
import SlideShowComp from "./SlideShowComp";
import GoogleAdvertisement from "../../Advertise/GoogleAdvertisement";
import { Link } from "react-router-dom";
import { generateSchemaData } from "../../../helper/schemaData";

const EstdSlideShow = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const [displaySingleImage, setDisplaySingleImage] = useState(false);
  const [nextImage, setNextImage] = useState(0);
  const data = props.data;
  console.log("a4 inside", data);
  const cards = data.story.cards;
  const allTypeArray = [];
  const imgArray = [];
  let count = 0;

  cards.map((card) => {
    card["story-elements"].map((element, index) => {
      if (element.subtype === "image-gallery") {
        const imgArray2 = [];

        element["story-elements"].map((imageData) => {
          imgArray.push({
            image: imgBaseURL + imageData["image-s3-key"],
            title: imageData["title"],
          });
          imgArray2.push({
            image: imgBaseURL + imageData["image-s3-key"],
            title: imageData["title"],
          });
        });
        allTypeArray[count++] = { type: "image", images: imgArray2 };
      } else if (element.type === "text" && !element.subtype) {
        allTypeArray[count++] = { type: "text", text: element.text };
      }
    });
  });
  console.log("cards", imgArray);
  console.log("newlogic", allTypeArray);
  allTypeArray.map((ar, index) => {});
  const heroData = {
    img: imgBaseURL + data["story"]["hero-image-s3-key"],
    imgTitle: data["story"]["hero-image-caption"],
    cat: data["story"]["sections"][0]["name"].toUpperCase(),
    title: data["story"]["headline"],
    auth: data["story"]["author-name"],
    authId: data["story"]["author-id"],
  };

  if ("home" in data["story"]["alternative"]) {
    if ("default" in data["story"]["alternative"]["home"]) {
      console.log("alernative", data["story"]["alternative"]["home"]);
      if ("hero-image" in data["story"]["alternative"]["home"]["default"]) {
        console.log(
          data["story"]["alternative"]["home"]["default"]["hero-image"][
            "hero-image-s3-key"
          ]
        );
        heroData.img =
          imgBaseURL +
          data["story"]["alternative"]["home"]["default"]["hero-image"][
            "hero-image-s3-key"
          ];
      }
    }
  }
  const handleNext = (arr) => {
    var element = document.getElementById("anime-id");
    element.classList.add("slide");
    element.classList.remove("active");
    setTimeout(() => {
      // document.querySelector("#anime-id").style.opacity = 0.5;
      if (nextImage >= arr.length - 1) {
        setNextImage(0);
      } else {
        const temp = nextImage + 1;
        console.log(temp);
        setNextImage(temp);
      }
    }, 0);
    setTimeout(() => {
      // document.querySelector("#anime-id").style.opacity = 1;
      element.classList.add("active");
    }, 500);
  };
  const handlePrevious = (arr) => {
    // document.querySelector(".slide-anime").style.opacity = 0;
    // document.querySelector(".slide-anime").style.transition='ease-in-out';

    var element = document.getElementById("anime-id");
    element.classList.add("slide");
    element.classList.remove("active");

    // element.classList.add("slide");

    // setTimeout(() => {
    //     element.classList.remove("slide");
    // }, 1200);
    // setTimeout(() => {
    //     element.classList.add("active");
    // }, 2500);
    setTimeout(() => {
      // document.querySelector("#anime-id").style.opacity = 0.5;
      if (nextImage === 0) {
        setNextImage(arr.length - 1);
      } else {
        const temp = nextImage - 1;
        console.log(temp);
        setNextImage(temp);
      }
    }, 0);
    setTimeout(() => {
      // document.querySelector("#anime-id").style.opacity = 1;
      element.classList.add("active");
    }, 500);
  };
  let readmoreCardNumber;
  cards.map((card, index) => {
    card["story-elements"].map((ele) => {
      if (ele.subtype === "also-read") {
        readmoreCardNumber = index;
      }
    });
  });
  const metaDataStory = {
    title: heroData.title,
    description: heroData.title,
    keywords: "The Established",
  };
  if ("seo" in data["story"]) {
    console.log("in seo");
    if ("meta-title" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-title"].length > 1) {
        metaDataStory.title = data["story"]["seo"]["meta-title"];
        console.log("in seo meta");
      } else {
        metaDataStory.title = heroData.title;
      }
    }
    if ("meta-description" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-description"].length > 1) {
        metaDataStory.description = data["story"]["seo"]["meta-description"];
      } else {
        metaDataStory.title = heroData.title;
        console.log("else in seo meta");
      }
    }
    if ("meta-keywords" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-keywords"].length > 1) {
        metaDataStory.keywords =
          data["story"]["seo"]["meta-keywords"].toString();
      }
    }
  } else {
    metaDataStory.title = heroData.title;
    metaDataStory.description = heroData.title;
  }

  let adCounter = 0;

  const getSchemaData = useMemo(() => {
    return generateSchemaData(props?.data, metaDataStory);
  }, [props.data]);

  return (
    <Article5Wrapper>
      <Helmet defer={false}>
        <title>{metaDataStory.title}</title>
        <meta
          name="description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />

        <meta
          property="twitter:url"
          content={window.location.href}
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <link
          rel="canonical"
          href={
            data["story"]["canonical-url"]
              ? data["story"]["canonical-url"]
              : window.location.href
          }
          data-react-helmet="true"
        />
        <meta name="author" content={heroData.auth} data-react-helmet="true" />
        <meta name="keywords" content={metaDataStory.keywords} />
      </Helmet>
      {/* Google Tag Manager (gtag.js) */}
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
        {props?.data && (
          <script type="application/ld+json">
            {JSON.stringify(getSchemaData)}
          </script>
        )}
      </Helmet>
      {/* <Header /> */}
      <div className="ss-format-web">
        <div className="slide-show-main-img">
          <img
            width="100%"
            src={heroData.img}
            // src={MainImage}
            alt="main-1"
          ></img>
        </div>
      </div>
      <div className="parent-div-article">
        <h1>
          <div className="slide-show-title InterstateCompressed-Bold">
            {heroData.title.toUpperCase()}
          </div>
        </h1>
        <div className="grid-5">
          <div>
            <div className="d-flex justify-content-center">
              <div className="category-style Biotif-Bold">{heroData.cat}</div>
            </div>
            <div className="ptp-3 text-center">
              <FacebookShareButton
                className="ss-s-icon-btn cursor-pointer"
                url={window.location.href}
                quote={heroData.title}
              >
                <img src={Facebook} className="ss-s-icon" alt="facebook"></img>
              </FacebookShareButton>
              <LinkedinShareButton
                className="ss-s-icon-btn cursor-pointer"
                title={heroData.title}
                url={window.location.href}
                description={metaDataStory.description}
                source={window.location.href}
              >
                <img
                  className="ss-s-icon"
                  src={Instagram}
                  alt="instagram"
                ></img>
              </LinkedinShareButton>
              <WhatsappShareButton
                className="cursor-pointer ss-s-icon-btn"
                title={heroData.title}
                url={window.location.href}
              >
                <img
                  className="eff1-social-images-whats-app cursor-pointer"
                  src={WhatsApp}
                  alt="WhatsApp"
                ></img>
              </WhatsappShareButton>
            </div>
          </div>
          <div className="ss-main-auth-div">
            <Link
              to={{
                pathname: `/author/${heroData.authId}/${heroData.auth
                  ?.replace(/ /g, "-")
                  .toLowerCase()}`,
                state: { story: data, authId: heroData.authId },
              }}
            >
              <div className="by-author Biotif-SemiBold pbp-1">
                By {heroData.auth}
              </div>
            </Link>
            <div className="by-date Biotif-Regular pbp-7">
              {moment(data["story"]["published-at"]).format("DD MMMM, YYYY")}
            </div>
          </div>
        </div>
        <div className="ss-format-mobile">
          <div className="slide-show-main-img">
            <img
              width="100%"
              src={heroData.img}
              // src={MainImage}
              alt="main-1"
            ></img>
          </div>
        </div>
        {allTypeArray.map((ele, index) =>
          ele.type === "text" ? (
            <>
              <div
                className="HeldaneDisplay-Regular ss-question"
                dangerouslySetInnerHTML={{ __html: ele.text }}
              ></div>
              {/* {(!(allTypeArray.length == index + 1) && adCounter < maxContentAd) &&
                            <>
                                <div className='d-none'>{adCounter++}</div>
                                <div className="web-view-ad-img web-view-ad-img-horizontal-small">
                                    <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal}
                                        styleHeight={horizontalSmallAdHeight} />
                                </div>
                                <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
                                    <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
                                </div>
                            </>
                            } */}
            </>
          ) : ele.type === "image" ? (
            <>
              <SlideShowComp images={ele.images} />
            </>
          ) : (
            ""
          )
        )}
      </div>

      {readmoreCardNumber >= 0 ? (
        <>
          <ReadMoreSection
            storyElement={cards[readmoreCardNumber]["story-elements"]}
          />
          {/* <div className="web-view-ad-img web-view-ad-img-horizontal-regular">
            <GoogleAdvertisement
              dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal}
            />
          </div>
          <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
            <GoogleAdvertisement
              dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square}
            />
          </div> */}
        </>
      ) : (
        ""
      )}
      {/* <Footer /> */}
    </Article5Wrapper>
  );
};
export default EstdSlideShow;

{
  /* {

                    displaySingleImage ? <div className='display-single-image-main' >
                        <div className='d-flex justify-content-end prp-2 cursor-pointer' onClick={() => setDisplaySingleImage(false)}>
                            <AiOutlineClose size={'2em'} />
                        </div>

                        <div className='display-single-image-body'>
                            <div className='display-single-image-board'>
                                <div className='display-single-image-img'>
                                    <img src={imgArray[nextImage].image} className='' id='anime-id' alt="" />
                                </div>
                            </div>
                            <div className='display-single-image-caption-btn'>
                                <div className='Biotif-SemiBold'>
                                    {nextImage + 1}/{imgArray.length}
                                </div>
                                <div className='display-single-image-caption' dangerouslySetInnerHTML={{ __html: imgArray[nextImage]['title'] }}>
                                </div>
                                <div className='display-single-image-btn' >
                                    <span onClick={handlePrevious} className='prp-3 cursor-pointer'>
                                        <AiOutlineArrowLeft />
                                    </span>
                                    <span onClick={handleNext} className='cursor-pointer'>
                                        <AiOutlineArrowRight />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> : <div className=''>
                        {
                            imgArray.length >= 6 ? <div className='grid-of-3 '>
                                {
                                    allTypeArray[1]['images'].slice(0, 5).map((img, index) => (
                                        <div className={`grid-temp grid-temp-${index} cursor-pointer`} onClick={() => {
                                            setDisplaySingleImage(true);
                                            setNextImage(index);
                                        }}>
                                            <img src={img.image} alt="" />
                                        </div>
                                    ))

                                }
                                <div className='grid-temp grid-temp-6'>
                                    <img src={imgArray[5].image} alt="" />
                                    <div className='grid-temp-6-fade cursor-pointer Biotif-SemiBold' onClick={() => {
                                        setDisplaySingleImage(true);
                                        setNextImage(0);
                                    }}>
                                        +{imgArray.length - 5}

                                    </div>
                                </div>

                            </div> : ''
                        }

                    </div>
                } */
}
