import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header2/Header';
import Footer from '../../components/common/Footer/Footer';
import SearchWrapper from './style';
import { Space, Card } from 'antd';
import { imgBaseURL } from '../../helper/constants';
import Search1 from "../../assets/images/Search-test.png";
import { withRouter } from 'react-router-dom';
import Api from '../../helper/Api';
import SearchWrapper2 from '../Style/style';
import { Link } from "react-router-dom";
import { subCatPageUrl } from '../../helper/constants'
import SubscribeSticky from '../../components/common/SubscribeSticky/SubscribeSticky';



const Search = (props) => {
  const [stories, setStories] = useState([]);
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    window.scroll(0,0);
  }, [])

  const trendResponse = props.location.state.trendResponse;
  useEffect(() => {
    setStories(props.location.state.searchResponse);
    setInputValue(props.location.state.inputValue);
  }, [props.location.state])
  const handleIndividualStory = (a) => {
    console.log('onclick,', a);
    props.history.push(`/${a['slug']}`);
  }
  const handleTrendingStory = (a) => {
    console.log('onclick');
    props.history.push(`/${a['story']['slug']}`, a);
  }
  function handleEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      Api('GET', '/api/v1/search', {}, { 'q': inputValue }).then((r) => {
        console.log('r.data.results.stories', r.data.results.stories)
        setStories(r.data.results.stories);
      })

    }
  }
  const alternateHeroImg = (story) => {
    let heroImage = imgBaseURL + story['hero-image-s3-key']
    // if('focus-point' in story['hero-image-metadata']){
    //   const reactVal=imageBounds([story['hero-image-metadata'].width,story['hero-image-metadata'].height],[1,1],[story['hero-image-metadata']['focus-point']])
    //   console.log('react value',reactVal)
    // }
    return heroImage
  }
  return (
    <SearchWrapper2>
      <SearchWrapper>
        <div>
          <Header />
          <div className='search-search-main-div' style={{ backgroundColor: 'black' }}>
            <div className="input-field-view plp-5 prp-5 d-flex">
              <div className="inside-search-tag"><img src={Search1} alt="search-1"></img></div>
              <div className="form__group-1 d-flex">
                <input style={{ borderBottom: '1px solid white' }} autocomplete="off" value={inputValue} onChange={e => setInputValue(e.target.value)} type="input" class="form__field-1 plp-1 Biotif-Regular" placeholder="Search" name="search" id='search' onKeyPress={(e) => handleEnter(e)} />
                <div className="arrow-input Biotif-Regular d-flex align-items-center search-clear-div cursor-pointer" onClick={() => setInputValue('')}>CLEAR</div>
              </div>
            </div>
            <div className='Biotif-SemiBold plp-5 prp-5 pbp-2 search-trending-div grey-text'>
              Trending:
              {

                trendResponse.slice(0, 3).map(item => (
                  <span onClick={() => { handleTrendingStory(item) }} className="cursor-pointer grey-text search-trending-hover search-trending-div Biotif-SemiBold pbp-1"> {item.story.headline},</span>
                ))
              }
              {
                <span onClick={() => { handleTrendingStory(trendResponse[3]) }} className="cursor-pointer grey-text search-trending-hover search-trending-div Biotif-SemiBold pbp-1"> {trendResponse[3].story.headline}.</span>
              }
            </div>
          </div>
          <div className='main-search'>
            <Space className='space-margin'>
              <div className='dot'></div>
              <div className='Biotif-SemiBold article-title'>Articles</div>
            </Space>
          </div>
          <div className='article-border'></div>
          <div className='mtp-5 mbp-10'>
            <div className='grid-new-1 mlp-15 mrp-15 pbp-3'>

              {stories.length >= 1 ? stories.map(story => (
                <div className='cursor-pointer'>
                  <Link to={{
                    pathname: `/${story['slug']}`
                  }}>
                  <div className='section-story-display-img'>
                    <img width="100%" src={alternateHeroImg(story)}></img>
                  </div>
                  <Link to={{
                      pathname: subCatPageUrl[story['sections'][0]['slug']].url,
                      state: { key: subCatPageUrl[story['sections'][0]['slug']].key }
                    }}>
                  <div className='sub-category Biotif-Book mtp-2'>
                    {story['sections'][0]['name'].toUpperCase()}
                  </div>
                  </Link>
                  <div className='question-category Biotif-SemiBold'>
                    {story.headline}
                  </div>
                  <div className='author Biotif-Book mbp-10'>
                    By {story['author-name']}
                  </div>
                  </Link>
                </div>
              )) :
                <div className='pbp-10 Biotif-Book'>
                  Sorry! No result found.
                </div>
              }
            </div>
          </div>
          {/* <div className='d-flex justify-content'>
          <button className='load-more-search Biotif-Regular'>LOAD MORE →</button>
        </div> */}
        <SubscribeSticky/>
          <Footer />
        </div>
      </SearchWrapper>
    </SearchWrapper2>
  );
};

export default withRouter(Search);
