import React from 'react'
import { imgBaseURL } from '../../../helper/constants'

const EstdInterviewCard1 = (props) => {
    const {cards, cardBgColor}=props
    console.log(props)
    const storyElement=cards['story-elements']
    console.log('story ele',storyElement)
 if(storyElement){
     return <div className=''>
<div className='row ma-0 row-data-2'>
                    {
                        storyElement.map((element)=>(
                            element.type==='text'?<div className='col-lg-6 col-xl-6 col-md-12 col-sm-12 p-0 '><div className='description-head HeldaneDisplay-Regular' dangerouslySetInnerHTML={{ __html: element.text }}>
                            </div></div>:""
                        ))
                    }
                {
                    storyElement.map((element)=>(
                        element.type==='image'?<div className='col-lg-6 col-xl-6 col-md-12 col-sm-12 p-0'><div className='interview-img-side'>
                            <img width="100%" src = {`${imgBaseURL}${element['image-s3-key']}`}alt='fashion'></img>
                            <div className='HeldaneDisplay-Regular image-text-2' dangerouslySetInnerHTML={{ __html: element['title']}}></div>
                        </div></div>:""
                        ))
                    }
            </div>
  </div>;
}
else return <div></div>
}

export default EstdInterviewCard1