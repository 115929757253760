import React, { useState, useEffect } from "react";
import { Space, Card, Spin } from "antd"; // Import Spin for loading indicator
import { Link } from "react-router-dom";
import { withRouter, useParams } from "react-router-dom";
import Header from "../../components/common/Header2/Header";
import Footer from "../../components/common/Footer/Footer";
import SearchWrapper from "../Search/style";
import SearchWrapper2 from "../Style/style";
import SubscribeSticky from "../../components/common/SubscribeSticky/SubscribeSticky";
import Api from "../../helper/Api";
import { imgBaseURL, subCatPageUrl } from "../../helper/constants";

const Author = (props) => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [paginationData, setPaginationData] = useState({
    limit: 45,
    offset: 0,
    total: 0,
  });

  let { id } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = () => {
    setLoading(true); // Show loading indicator
    Api(
      "GET",
      `/api/v1/author/stories/${Number(id)}?limit=${
        paginationData?.limit
      }&offset=${paginationData?.offset}`,
      {},
      {}
    )
      .then((r) => {
        setStories([...stories, ...r.data.items]);
        setPaginationData((prev) => {
          return {
            limit: 45,
            offset: r.data.items?.length + prev?.offset || 0,
            total: r.data?.["total-count"],
          };
        });
      })
      .finally(() => setLoading(false)); // Hide loading indicator when request completes
  };

  const alternateHeroImg = (story) => {
    let heroImage = imgBaseURL + story["hero-image-s3-key"];
    return heroImage;
  };

  return (
    <SearchWrapper2>
      <SearchWrapper>
        <div>
          <Header />

          <div className="main-author">
            <Space className="space-margin">
              <div className="dot"></div>
              <div className="Biotif-SemiBold article-title">
                Articles by{" "}
                {props.location?.state?.story?.story?.["author-name"] ||
                  (stories?.length >= 1 &&
                    stories[0]?.story?.[["author-name"]])}
              </div>
            </Space>
          </div>
          <div className="article-border"></div>
          <div className="mtp-5 mbp-10">
            <div className="grid-new-1 mlp-15 mrp-15 pbp-3">
              {loading && !stories?.length ? (
                <div className="spinner-container">
                  <Spin size="large" style={{ color: "red" }} />
                </div>
              ) : stories?.length >= 1 ? (
                stories.map((story) => (
                  <div key={story.story.slug} className="cursor-pointer">
                    <Link to={{ pathname: `/${story.story.slug}` }}>
                      <div className="section-story-display-img">
                        <img
                          width="100%"
                          src={alternateHeroImg(story.story)}
                          alt={story.story.slug}
                        />
                      </div>
                      <Link
                        to={{
                          pathname:
                            subCatPageUrl[story.story.sections[0].slug].url,
                          state: {
                            key: subCatPageUrl[story.story.sections[0].slug]
                              .key,
                          },
                        }}
                      >
                        <div className="sub-category Biotif-Book mtp-2">
                          {story.story.sections[0].name.toUpperCase()}
                        </div>
                      </Link>
                      <div className="question-category Biotif-SemiBold">
                        {story.item.headline[0]}
                      </div>
                      <div className="author Biotif-Book mbp-10">
                        By {story.story["author-name"]}
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="pbp-10 Biotif-Book">
                  Sorry! No result found.
                </div>
              )}
            </div>
            {paginationData?.offset < paginationData?.total ? (
              <div className="d-flex justify-content Biotif-Regular mbp-8 section-read-more">
                <button className="read-more-btn" onClick={fetchData}>
                  SHOW MORE →
                </button>
              </div>
            ) : (
              <div className="mbp-10"></div>
            )}
          </div>

          <SubscribeSticky />
          <Footer />
        </div>
      </SearchWrapper>
    </SearchWrapper2>
  );
};

export default withRouter(Author);
