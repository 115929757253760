import React from "react";
import "./style.css";
import culture from "../../assets/images/title_svg_culture.svg";
import AdImg from "../../assets/images/Website Ads-18.jpg";
import { imgBaseURL } from '../../helper/constants';
import { withRouter } from "react-router-dom";
import AdPillar from "../Advertise/AdPillar";
import { Link } from "react-router-dom";
import { subCatPageUrl } from '../../helper/constants'


function CultureSection(props) {
  const resp = props.resp
  const handleIndividualStory = (a) => {
    console.log('onclick', a['story']['sections'][0]);
    props.history.push(`/${a['story']['slug']}`, a);
  }
  if (resp.length) {
    return (
      <div className="mtp-5">
        <div className="culture-heading InterstateCompressed-Bold">
          <img src={culture} alt="culture logo" className="cursor-pointer" onClick={() => { props.history.push('/culture') }} />
        </div>
        <div className="row ma-0 culture-main-row">
          <div className="col-lg-9 col-xl-9  col-md-12 col-sm-12 col-xs-12 p-0 m-0">
            <div className="culture-content-main">
              {
                resp.slice(0, 3).map((a, index) => (
                  <div className={`culture-content-${index + 1} culture-content`}>
                    <Link to={{
                      pathname: `/${a['story']['slug']}`,
                      state: { story: a }
                    }}>
                      <img src={`${imgBaseURL}${a['story']['hero-image-s3-key']}`} alt="" />
                      <Link to={{
                        pathname: subCatPageUrl[a['story']['sections'][0]['slug']].url,
                        state: { key: subCatPageUrl[a['story']['sections'][0]['slug']].key }
                      }}>
                        <div className="culture-content-cat Biotif-Book">
                          {a['story']['sections'][0]['name'].toUpperCase()}
                        </div>
                      </Link>
                      <div className="culture-content-title Biotif-SemiBold">
                        {a['story']['headline']}
                      </div>
                      <div className="culture-content-author Biotif-Book">
                        By {a['story']['author-name']}
                      </div>
                    </Link>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
            <div className="culture-more-read-main">
              <div className="culture-more-read InterstateCompressed-Bold">
                <div className="culture-more-read-1 InterstateCompressed-Bold">MORE READS</div>
              </div>
              <div className="culture-more-read-warp">
                {
                  resp.slice(4, 6).map((a, index) => (
                    <div className={`culture-content-read-more culture-content culture-content-${index + 3}`}>
                      <Link to={{
                        pathname: `/${a['story']['slug']}`,
                        state: { story: a }
                      }}>
                        <Link to={{
                          pathname: subCatPageUrl[a['story']['sections'][0]['slug']].url,
                          state: { key: subCatPageUrl[a['story']['sections'][0]['slug']].key }
                        }}>
                          <div className="culture-content-cat culture-content-rm-cat Biotif-Book">
                            {a['story']['sections'][0]['name'].toUpperCase()}
                          </div>
                        </Link>
                        <div className="culture-content-title culture-content-rm-titile Biotif-SemiBold">
                          {a['story']['headline']}
                        </div>
                        <div className="culture-content-author culture-content-rm-author Biotif-Book">
                          By {a['story']['author-name']}
                        </div>
                      </Link>
                    </div>
                  ))
                }
                {/* <div className="Biotif-Bold culture-content-read-btn">
                  <div className="culture-content-read-btn-1" onClick={() => props.history.push('/culture')}>READ MORE →</div>
                </div> */}
                <AdPillar img={AdImg} linkStatus='internal' url='/news-letter' />
              </div>
            </div>
          </div>
        </div>
        <div className="mbp-5">
          <div className="culture-image-section">
            <div className="culture-image-image cursor-pointer">
            <Link to={{
                        pathname: `/${resp[3]['story']['slug']}`,
                        state: { story: resp[3]}
                      }}>
              <img src={`${imgBaseURL}${resp[3]['story']['hero-image-s3-key']}`} alt="" />
                      </Link>
            </div>
            <div className="culture-image-content">
              <div>
              <Link to={{
                        pathname: `/${resp[3]['story']['slug']}`,
                        state: { story: resp[3]}
                      }}>
                <div className="culture-image-content-head HeldaneDisplay-RegularItalic">Editor’s Pick</div>
                <div className="culture-image-content-title Biotif-SemiBold cursor-pointer">
                  {resp[3]['story']['headline'].toUpperCase()}
                </div>
                <div className="culture-image-content-bottom Biotif-SemiBold cursor-pointer">
                  {resp[3]['story']['subheadline']}
                </div>
                <div className="culture-image-content-btn Biotif-Regular">
                  <button>READ MORE →</button>
                </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return <div></div>
  }
}
export default (withRouter)(CultureSection)
