import React from 'react';
import { withRouter } from 'react-router-dom';
import SpotlightWrapper from './style';
import { Link } from "react-router-dom";
import { subCatPageUrl } from '../../helper/constants'

const SpotlightNew = (props) => {
    const resp=props.resp;
    const handleIndividualStory=(a)=>{
        console.log('onclick',a['story']['sections'][0]);
            props.history.push(`/${a['story']['slug']}`, a);
    }
    if(resp.length){
    return (
        <SpotlightWrapper>
            <div className='spotlight'>
            <div className='spotlight-head InterstateCompressed-Bold'>THE SPOTLIGHT</div>
            {resp.slice(0,5).map((a,index)=>(
                <div className='spolight-main' style={{cursor:'pointer'}}>
                    <Link to={{
                    pathname: `/${a['story']['slug']}`,
                    state: { story: a }
                  }}>
                      <Link to={{
                      pathname: subCatPageUrl[a['story']['sections'][0]['slug']].url,
                      state: { key: subCatPageUrl[a['story']['sections'][0]['slug']].key }
                    }}>
                    <div className='Biotif-Book spotlight-cat'>
                    {a['story']['sections'][0]['name'].toUpperCase()}
                    </div>
                    </Link>
                    <div className='d-flex'>
                            <div className='spotlight-index InterstateCompressed-Bold neon'>{index+1}</div>
                            <div className='spotlight-title InterstateCompressed-Bold'>
                            {a['story']['headline'].toUpperCase()}
                             </div>
                            </div>
                            <div className='spotlight-auth Biotif-Book'>
                            By {a['story']['author-name']}
                            </div>
                            <div className='spotlight-border'></div>
                  </Link>
                </div>
            ))}
        </div>
        </SpotlightWrapper>
    )
}
else{
    return <div></div>
}
}

export default (withRouter) (SpotlightNew);
