import React from 'react';
import './style.css';
import { imgBaseURL } from '../../helper/constants';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { subCatPageUrl } from '../../helper/constants'


function CommonSection(props) {

    const handleIndividualStory = (a) => {
        console.log('onclick', a['story']['sections'][0]);
        props.history.push(`/${a['story']['slug']}`, a);
    }
    // const resp={
    //     0:{

    //         img:img1,
    //         cat:'STYLE',
    //         title:'THE COMPLETE GUIDE TO NFTS',
    //         auth:'Shruti Thacker'
    //     },
    //     1:{

    //         img:img2,
    //         cat:'CULTURE',
    //         title:'THE RESTAURANT SURVIVAL STRATEGY',
    //         auth:'Roshni Bajaj Sanghvi'
    //     },
    //     2:{

    //         img:img3,
    //         cat:'CULTURE',
    //         title:'EVERYTHING YOU’RE WEARING IS DICTATED BY A SPORTING GIANT',
    //         auth:'Shruti Thacker'
    //     }
    // }
    const resp = props.resp
    console.log('img link', resp)
    if (resp.length) {
        console.log(resp, 'from cs')
        return (
            <div className='common-section'>
                <div className='common-partone'>
                    {/* <div className='common-header-name'>
                        <img src={logo} alt="logo" />
                    </div> */}
                </div>
                <div className='common-parttwo'>
                    <div className='common-main-div'>
                        {
                            resp.map((a, index) => (
                                <div className={`common-main-${index + 1} d-flex  flex-column`}>
                                    <Link to={{
                                        pathname: `/${a['story']['slug']}`,
                                        state: { story: a }
                                    }}>

                                        <div style={{ cursor: 'pointer' }}>
                                            <img src={`${imgBaseURL}${a['story']['hero-image-s3-key']}`} alt="" />
                                            <Link to={{
                                    pathname:subCatPageUrl[a['story']['sections'][0]['slug']].url,
                                    state:{key:subCatPageUrl[a['story']['sections'][0]['slug']].key}
                                }}>
                                            <div className='Biotif-Book common-category'>
                                                {a['story']['sections'][0]['name'].toUpperCase()}
                                            </div>
                                </Link>
                                            <div className='common-main-1-text InterstateCompressed-Bold'>
                                                {/* THE COMPLETE GUIDE TO NFTS */}
                                                {a['story']['headline'].toUpperCase()}
                                            </div>
                                            <div className='common-author Biotif-Book'>By {a['story']['author-name']} </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
    else {
        return <div></div>
    }
}
export default (withRouter)(CommonSection)