import React, { useState } from "react";
import StyleWrapper from "./style";
import Drawer from "../../../assets/images/nav.svg";
import { withRouter } from "react-router";
import styled from 'styled-components'
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { subscribeActions } from "../../../redux/subscribe/actions";
import Api from "../../../helper/Api";
import authActions from "../../../redux/auth/actions";
import Navbar from "../Navbar2";
import logo from '../../../assets/images/LOGO.svg'

const { updateCartItem, cartItems, productAdded, subscriptionAdded } = subscribeActions;
const { logout, userData } = authActions;

const Header = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [checkoutModal, setCheckoutModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [errMessage, setErrMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [state, setState] = React.useState({
    openError: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, openError } = state;


  const openMenu = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = (newState) => {
    setAnchorEl(null);
    Api('GET', `/auth/logout`).then(res => {
      setSuccessMessage(res.data.message);
      dispatch(logout({ type: "LOGOUT" }));
      dispatch(userData({ type: "USER_DATA", user_data: undefined }))
      dispatch(cartItems({ type: "ADD_TO_CART", cartItems: [] }))
      dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: [] }))
      setIsValid(true);
      setState({ openError: true, ...newState });

    })
      .catch(err => {
        setIsValid(false);
        setErrMessage(JSON.parse(err.request.response).message);
        setState({ openError: true, ...newState });
        console.log('err', err);
      })
  }
  const toggleDrawer = (openNew) => {
    setOpen(openNew);
  };

  const handleIncrement = (item, val) => {
    let newList = [...props.cartItems];
    let newData = newList.find(x => x.id === Number(item));
    newData.count = newData.count + 1;
    newList.splice(val, 1, newData);
    dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: newList }))
  }

  const handleDecrement = (item, val) => {
    let newList = [...props.cartItems];
    let newData = newList.find(x => x.id === Number(item));
    if (newData.count > 1) {
      newData.count = newData.count - 1;
      newList.splice(val, 1, newData);
      dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: newList }))
    }
    else {
      let filteredCartList = newList.filter(x => x.id !== Number(item))
      if (!filteredCartList.length) {
        dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
        dispatch(subscriptionAdded({ type: "SUBSCRIPTION_ADDED", subscriptionAdded: false }))
      }
      if (filteredCartList.length) {
        filteredCartList.map(filteredItem => {
          if (filteredItem.hasOwnProperty('stripe_price_id')) {
            dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
          } else {
            dispatch(subscriptionAdded({ type: "SUBSCRIPTION_ADDED", subscriptionAdded: false }))
          }

        })
      }
      dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: filteredCartList }))
    }
  }

  const toggle = () => {
    setCheckoutModal(!checkoutModal);
  }
  const handleCheckout = () => {
    if (props.userData?.user_data !== undefined) {
      var checkoutParams = {
        blok_qty: "",
        is_blok_added: false,
        subscription_id: "",
        subscription_qty: "",
        user_id: props.userData.user_data.id
      }
      props.cartItems.map(cartItem => {
        if (cartItem.hasOwnProperty('stripe_price_id')) {
          checkoutParams.subscription_id = cartItem.id;
          checkoutParams.subscription_qty = cartItem.count;
        }
        if (cartItem.hasOwnProperty('isProduct')) {
          checkoutParams.is_blok_added = true;
          checkoutParams.blok_qty = cartItem.count
        }

      });
      Api("POST", `checkout/create-session`, checkoutParams).then((res) => {
        window.location.href = res.data.data.url;
      })
        .catch(err => {
          console.log('err', err);
        })
    } else {
      setCheckoutModal(true);
    }
    setOpen(false);
  }
  return (
    <StyleWrapper>
       <div>
      <div className="ptbp-1 header-background" id="nav-bar-2">
        <Navbar/>
      </div>
      </div>
    </StyleWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionData: state.subscribe.subscriptionData,
    cartItems: state.subscribe.cartItems,
    updatedCartItems: state.subscribe.cartItems,
    userData: state.auth.user_data
  }
}

export default connect(mapStateToProps)(withRouter(Header));
