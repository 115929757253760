import React from "react";
import { withRouter } from "react-router-dom";
import { imgBaseURL } from "../../helper/constants";
import "./style.css";
import Advertisement from "../Advertise/Advertisement";
// import GoogleAdvertisement from "../Advertise/GoogleAdvertisement";
// import styleAd from "../../assets/images/R&M Style.jpg";
// import selfAd from "../../assets/images/TA Home3.gif";
// import cultureAd from "../../assets/images/R&M Culture.jpg";
// import communityAd from "../../assets/images/TA Home3.gif";
// import styleAdSq1 from "../../assets/images/R&M_Sq Style.jpg";
// import selfAdSq2 from "../../assets/images/TA Sq.gif";
// import cultureAdSq3 from "../../assets/images/R&M_Sq Culture.jpg";
// import communityAdSq4 from "../../assets/images/TA Sq.gif";
import {
  styleAd,
  styleAdSq1,
  selfAd,
  selfAdSq2,
  cultureAd,
  cultureAdSq3,
  communityAd,
  communityAdSq4,
} from "../../helper/constants";
import { Link } from "react-router-dom";
import {
  subCatPageUrl,
  styleSubAds,
  selfSubAds,
  cultureSubAds,
  communitySubAds,
} from "../../helper/constants";

const SectionStoryDisplay = (props) => {
  const { stories, cat } = props;
  const subAds =
    cat === "style"
      ? styleSubAds
      : cat === "self"
      ? selfSubAds
      : cat === "culture"
      ? cultureSubAds
      : cat === "community"
      ? communitySubAds
      : [];
  const handleIndividualStory = (a) => {
    console.log("onclick", a);
    props.history.push(`/${a["slug"]}`);
  };
  console.log("section display", stories);
  const alternateHeroImg = (story) => {
    let heroImage = imgBaseURL + story["hero-image-s3-key"];
    console.log("story images", story.alternative);
    if (story["alternative"]) {
      if ("home" in story["alternative"]) {
        if ("default" in story["alternative"]["home"]) {
          console.log("alernative", story["alternative"]["home"]);
          if ("hero-image" in story["alternative"]["home"]["default"]) {
            if (story["alternative"]["home"]["default"]["hero-image"]) {
              if (
                "hero-image-s3-key" in
                story["alternative"]["home"]["default"]["hero-image"]
              ) {
                console.log(
                  story["alternative"]["home"]["default"]["hero-image"][
                    "hero-image-s3-key"
                  ]
                );
                heroImage =
                  imgBaseURL +
                  story["alternative"]["home"]["default"]["hero-image"][
                    "hero-image-s3-key"
                  ];
              }
            }
          }
        }
      }
    }
    return heroImage;
  };
  return (
    <div className="">
      {stories.length === 0 ? (
        <div
          className="text-center mtp-5 mbp-5"
          style={{ height: "80vh" }}
        ></div>
      ) : stories.length === 1 ? (
        <div>
          <div className="sd-one-story-only">
            {stories.map((story, index) => (
              <div className="cursor-pointer">
                <Link
                  to={{
                    pathname: `/${story["slug"]}`,
                    state: { story: story },
                  }}
                >
                  <div>
                    <img
                      width="100%"
                      src={`${imgBaseURL}${story["hero-image-s3-key"]}`}
                      alt={index}
                    ></img>
                  </div>
                  <Link
                    to={{
                      pathname: subCatPageUrl[story["sections"][0]["slug"]].url,
                      state: {
                        key: subCatPageUrl[story["sections"][0]["slug"]].key,
                      },
                    }}
                  >
                    <div className="sub-category Biotif-Book">
                      {story["sections"][0]["display-name"].toUpperCase()}
                    </div>
                  </Link>
                  <div className="question-category Biotif-SemiBold">
                    {story["headline"]}
                  </div>
                  <div className="author Biotif-Book">
                    By {story["author-name"]}
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="web-view-ad-img">
            {cat === "style" ? (
              <Advertisement
                img={styleAd.img}
                linkStatus={styleAd.linkStatus}
                url={styleAd.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} />
            ) : cat === "self" ? (
              <Advertisement
                img={selfAd.img}
                linkStatus={selfAd.linkStatus}
                url={selfAd.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} />
            ) : cat === "culture" ? (
              <Advertisement
                img={cultureAd.img}
                linkStatus={cultureAd.linkStatus}
                url={cultureAd.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} />
            ) : cat === "community" ? (
              <Advertisement
                img={communityAd.img}
                linkStatus={communityAd.linkStatus}
                url={communityAd.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} />
            ) : (
              ""
            )}
          </div>
          <div className="mob-view-ad-img plp-5 prp-5 pbp-5">
            {cat === "style" ? (
              <Advertisement
                img={styleAdSq1.img}
                linkStatus={styleAdSq1.linkStatus}
                url={styleAdSq1.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
            ) : cat === "self" ? (
              <Advertisement
                img={selfAdSq2.img}
                linkStatus={selfAdSq2.linkStatus}
                url={selfAdSq2.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
            ) : cat === "culture" ? (
              <Advertisement
                img={cultureAdSq3.img}
                linkStatus={cultureAdSq3.linkStatus}
                url={cultureAdSq3.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
            ) : cat === "community" ? (
              <Advertisement
                img={communityAdSq4}
                linkStatus={communityAdSq4.linkStatus}
                url={communityAdSq4.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : stories.length >= 2 ? (
        <div>
          <div className="grid-view mlp-15 mrp-15 mbp-5">
            {stories.slice(0, 2).map((story, index) => (
              <div className="cursor-pointer">
                <Link
                  to={{
                    pathname: `/${story["slug"]}`,
                    state: { story: story },
                  }}
                >
                  <div>
                    <img
                      width="100%"
                      src={`${imgBaseURL}${story["hero-image-s3-key"]}`}
                      alt={index}
                    ></img>
                  </div>
                  <Link
                    to={{
                      pathname: subCatPageUrl[story["sections"][0]["slug"]].url,
                      state: {
                        key: subCatPageUrl[story["sections"][0]["slug"]].key,
                      },
                    }}
                  >
                    <div className="sub-category Biotif-Book">
                      {story["sections"][0]["display-name"].toUpperCase()}
                    </div>
                  </Link>
                  <div className="question-category Biotif-SemiBold">
                    {story["headline"]}
                  </div>
                  <div className="author Biotif-Book">
                    By {story["author-name"]}
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="web-view-ad-img">
            {cat === "style" ? (
              <Advertisement
                img={styleAd.img}
                linkStatus={styleAd.linkStatus}
                url={styleAd.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} />
            ) : cat === "self" ? (
              <Advertisement
                img={selfAd.img}
                linkStatus={selfAd.linkStatus}
                url={selfAd.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} />
            ) : cat === "culture" ? (
              <Advertisement
                img={cultureAd.img}
                linkStatus={cultureAd.linkStatus}
                url={cultureAd.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} />
            ) : cat === "community" ? (
              <Advertisement
                img={communityAd.img}
                linkStatus={communityAd.linkStatus}
                url={communityAd.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} />
            ) : (
              ""
            )}
          </div>
          <div className="mob-view-ad-img plp-5 prp-5 pbp-5">
            {cat === "style" ? (
              <Advertisement
                img={styleAdSq1.img}
                linkStatus={styleAdSq1.linkStatus}
                url={styleAdSq1.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
            ) : cat === "self" ? (
              <Advertisement
                img={selfAdSq2.img}
                linkStatus={selfAdSq2.linkStatus}
                url={selfAdSq2.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
            ) : cat === "culture" ? (
              <Advertisement
                img={cultureAdSq3.img}
                linkStatus={cultureAdSq3.linkStatus}
                url={cultureAdSq3.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
            ) : cat === "community" ? (
              <Advertisement
                img={communityAdSq4.img}
                linkStatus={communityAdSq4.linkStatus}
                url={communityAdSq4.url}
              />
              // <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
            ) : (
              ""
            )}
          </div>
          <div className="pink-border mtp-5"></div>
          <div className="grid-new-1 mlp-15 mrp-15">
            {stories.slice(2).map((story, index) => (
              <>
                <div className="cursor-pointer">
                  <Link
                    to={{
                      pathname: `/${story["slug"]}`,
                      state: { story: story },
                    }}
                  >
                    <div className="section-story-display-img">
                      <img
                        width="100%"
                        src={alternateHeroImg(story)}
                        alt={index}
                      ></img>
                    </div>
                    <Link
                      to={{
                        pathname:
                          subCatPageUrl[story["sections"][0]["slug"]].url,
                        state: {
                          key: subCatPageUrl[story["sections"][0]["slug"]].key,
                        },
                      }}
                    >
                      <div className="sub-category Biotif-Book">
                        {" "}
                        {story["sections"][0]["display-name"].toUpperCase()}
                      </div>
                    </Link>
                    <div className="question-category Biotif-SemiBold">
                      {story["headline"]}
                    </div>
                    <div className="author Biotif-Book mbp-10">
                      By {story["author-name"]}
                    </div>
                  </Link>
                </div>
                {(index + 1) % 12 === 0 &&
                index >= 11 &&
                index + 1 !== stories?.length - 2 &&
                subAds?.length >= 1 ? (
                  <div className="full-lenght-ads mbp-2">
                    <div className="web-view-ad-img">
                      <Advertisement
                        fullWidth={true}
                        img={
                          subAds?.[
                            Math.floor(Math.floor(index / 12) % subAds.length)
                          ]?.webViewImg
                        }
                        linkStatus={
                          subAds?.[
                            Math.floor(Math.floor(index / 12) % subAds.length)
                          ]?.linkStatus
                        }
                        url={
                          subAds?.[
                            Math.floor(Math.floor(index / 12) % subAds.length)
                          ]?.url
                        }
                      />
                      {/* <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} /> */}
                    </div>
                    <div className="mob-view-ad-img mbp-10">
                      <Advertisement
                        img={
                          subAds?.[
                            Math.floor(Math.floor(index / 12) % subAds.length)
                          ]?.mobViewImg
                        }
                        linkStatus={
                          subAds?.[
                            Math.floor(Math.floor(index / 12) % subAds.length)
                          ]?.linkStatus
                        }
                        url={
                          subAds?.[
                            Math.floor(Math.floor(index / 12) % subAds.length)
                          ]?.url
                        }
                      />
                      {/* <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} /> */}
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          </div>
        </div>
      ) : (
        <div style={{ height: "80vh" }}></div>
      )}
    </div>
  );
};

export default withRouter(SectionStoryDisplay);
