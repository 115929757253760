import React from "react";
import LeftMenu from "./LeftMenu";
import { Drawer } from "antd";
import "antd/dist/antd.css";
import { useState,useEffect } from "react";
import Search from "../../../assets/images/search.svg";
import Search1 from "../../../assets/images/Search-test.png";
import NavDrawer from "../../../assets/images/bar11.png";
//import "../../../../src/App.css";
import "../../../../src/App.css";
import "antd/dist/antd.css";
import HamShow from "./HamShow";
import logo2 from '../../../assets/images/LOGO2.svg'
import HamFooter from "./HamFooter";
import { withRouter } from 'react-router-dom';
import StyleWrapper from './style';
import ModernDrawer from 'react-modern-drawer';
import Api from '../../../helper/Api';
import { useMediaQuery } from "react-responsive";
import logo from '../../../assets/images/LOGO.svg'



//import styles 👇
import 'react-modern-drawer/dist/index.css'

const Navbar = (props)=> {
  console.log('props', props);
  const [visible, setVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [category, setCategory] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [trendResponse, setTrendResponse] = useState([]);
  useEffect(() => {
    Api('GET', '/api/v1/collections/breaking-news', {}, {}).then((r) => {
      console.log('header breaking news', r);
      setTrendResponse(r.data.items);
  })
  }, [])
  useEffect(() => {
    Api('GET', '/api/v1/search', {}, { 'q': inputValue}).then((r) => {
      console.log('showing story for style', r);
      setSearchResponse(r.data.results.stories);

  })


  }, [inputValue])

  const handleTrendingStory=(a)=>{
    console.log('onclick');
    setSearchVisible(false);
    props.history.push(`/${a['story']['slug']}`, a);
  }
  const onChange = e => {
    console.log(e);
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const showDrawerSearch = () => {
    setSearchVisible(true);
  }
  const onClose = () => {
    setVisible(false);
    console.log('clicked');
    setCategory('');
  };
  const displayCategory = (data) => {
    setCategory(data);
  }
  const displayHideCategory = () => {
    setTimeout(() => {
      setCategory('');
    }, 10000);

  }
  const onCloseSearch = () => {
    setSearchVisible(false);
  }
  const handleIndividualStory=(a)=>{
    console.log('onclick');
    setSearchVisible(false);

        props.history.push(`/${a['slug']}`);
}
// onClick={()=>props.history.push('/search',searchResponse)}
function handleEnter(e){
  console.log('enter')
  if(e.key === 'Enter'){
      e.preventDefault(); // Ensure it is only this code that runs
      props.history.push('/search',{searchResponse:searchResponse,trendResponse:trendResponse,inputValue:inputValue})
      setSearchVisible(false);
      }
}
const PhoneView = useMediaQuery({ minWidth: 300, maxWidth: 480 });
  //className="menuBar"
    return (
      <StyleWrapper>
      <div className="d-flex justify-content-around" >
        <div className="search-div search-div-2">
         <img onClick={showDrawerSearch} className="cursor-pointer" width="100%"  src={Search} alt = "search-icon"></img>
         <div className="search-bar-drawer">
          <ModernDrawer className="modern-drawer" style={{background:'black'}}
            direction="top"
            //closable={true}
            onClose={onCloseSearch}
            open={searchVisible}
          >
            <div className="input-field-view plp-5 d-flex">
              <div className="inside-search-tag"><img src={Search1} alt="search-1"></img></div>
              <div className="form__group-1 d-flex">
                <input style={{borderBottom:'1px solid white'}} autocomplete="off" onKeyPress={(e)=>handleEnter(e)} value={inputValue} onChange={e => setInputValue(e.target.value)} type="input" class="form__field-1 plp-1 Biotif-Regular" placeholder="Search" name="search" id='search' />
                {/* <div className="arrow-input"><img src = {Arrow} alt="arrow"></img></div> */}
              </div>
            </div>
            <div className="ptp-2 fs-30">
              {inputValue!=='' && searchResponse.length>=1 ? searchResponse.slice(0,5).map(item => (
                <div onClick={()=>{handleIndividualStory(item)}}  className="text-white search-result-div plp-5 prp-5 cursor-pointer Biotif-SemiBold pbp-1">{item.headline}</div>
              )) : trendResponse.slice(0,5).map(item => (
                <div onClick={()=>{handleTrendingStory(item)}}  className="text-white search-result-div plp-5 cursor-pointer Biotif-SemiBold pbp-1">{item.story.headline}</div>
              ))}
            </div>

          </ModernDrawer>
        </div>
        </div>
        <div className="menuCon menuCon2 newiconh2 d-flex justify-content-center" style={{cursor:'pointer'}} onClick={()=>{props.history.push('/'); window.scroll(0,0)}}>
        {
          PhoneView?<img width="100%" src = {logo} alt="the-established"></img>:<img width="100%" src = {logo2} alt="the-established"></img>
        }

        </div>
          <div className="cursor-pointer-main">

          <img onClick={showDrawer} className="cursor-pointer" width="100%" src={NavDrawer} alt = "drawer-icon"></img>
          <Drawer
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
          >
            {/* <LeftMenu /> */}
            <HamShow displayCategory={displayCategory} category={category} displayHideCategory={displayHideCategory} onClose={onClose} />
            <HamFooter/>
            {/* <RightMenu /> */}
          </Drawer>
          </div>
        </div>
        </StyleWrapper>
    );
  }
export default (withRouter) (Navbar);
