import React from "react";
import StyleWrapper from "./style";
import InstagramIcon from "../../../assets/images/insta1.svg";
import FacebookIcon from "../../../assets/images/fb1.svg";
import LinkedinIcon from "../../../assets/images/linkedinlogo.svg";
import Twitter from "../../../assets/images/twitter1.svg";
import Youtube from "../../../assets/images/yt-icon-2.png";
import Established from "../../../assets/images/TheEstablished.svg";
import { withRouter } from "react-router";
import Arrow from "../../../assets/images/arrow11.png";

const Footer = (props) => {
  const handleAboutUs = () => {
    console.log("clicked");
    props.history.push("/about-us");
  };
  const handlePrivacy = () => {
    console.log("clicked");
    props.history.push("/privacy-policy");
  };
  return (
    <div>
      <StyleWrapper>
        <div className="black-bg text-white footer-main-div-0">
          <div className="footer-part-1">
            <div className="footer-estd-logo-main">
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (window.location.pathname === "/") {
                    window.scroll(0, 0);
                  } else {
                    props.history.push("/");
                  }
                }}
              >
                <img src={Established} alt="" className="footer-estd-logo" />
              </div>
              <div className="footer-cat">
                <div
                  className="cursor-pointer"
                  onClick={() => props.history.push("/style")}
                >
                  STYLE
                </div>
                <div>|</div>
                <div
                  className="cursor-pointer"
                  onClick={() => props.history.push("/self")}
                >
                  SELF
                </div>
                <div>|</div>
                <div
                  className="cursor-pointer"
                  onClick={() => props.history.push("/culture")}
                >
                  CULTURE
                </div>
                <div>|</div>
                <div
                  className="cursor-pointer footer-com-div"
                  onClick={() => props.history.push("/community")}
                >
                  COMMUNITY
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex s-icon-div">
                <div>
                  <img
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/theestablishedcom",
                        "_blank"
                      )
                    }
                    src={FacebookIcon}
                    alt="FB icon"
                    className="cursor-pointer"
                  ></img>
                </div>
                <div>
                  <img
                    onClick={() =>
                      window.open(
                        "https://instagram.com/theestablished_",
                        "_blank"
                      )
                    }
                    src={InstagramIcon}
                    alt="Insta icon"
                    className="cursor-pointer"
                  ></img>
                </div>
                <div>
                  <img
                    onClick={() =>
                      window.open(
                        "https://in.linkedin.com/company/theestablished",
                        "_blank"
                      )
                    }
                    src={LinkedinIcon}
                    alt="Linkedin icon"
                    className="cursor-pointer"
                  ></img>
                </div>
                <div className="mr-0">
                  <img
                    onClick={() =>
                      window.open(
                        "https://www.youtube.com/@TheEstablished11",
                        "_blank"
                      )
                    }
                    src={Youtube}
                    alt="Youtube icon"
                    className="cursor-pointer mr-0"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-part-2 ptp-7 pbp-2">
            <div className="d-flex footer-bottom-pages">
              <div
                className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
                onClick={handleAboutUs}
              >
                ABOUT US
              </div>
            
              <div
                className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
                onClick={() => props.history.push("/partner-with-us")}
              >
                PARTNER WITH US
              </div>
              <div
                className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
                onClick={handlePrivacy}
              >
                PRIVACY
              </div>
              <div
                className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
                onClick={() => props.history.push("/news-letter")}
              >
                SUBSCRIBE
              </div>
            </div>
            <div className="Biotif-Regular footer-theestd">
              © The Established 2021
            </div>
          </div>
        </div>
      </StyleWrapper>
    </div>
  );
};

export default withRouter(Footer);

// <div className="black-bg text-white">
// <div className="row ma-0 ptp-3 plrp-3">
//   <div className="col-lg-6  col-md-12 col-sm-12 col-xs-12">
//     <div className="newsletter-subscribe">
//       <div className="Biotif-Regular newsletter ">SUBSCRIBE TO OUR NEWSLETTER</div>
//       <div className="input-field-view">
//         <div class="form__group field d-flex">
//           <input type="input" class="form__field" placeholder="Your e-mail" name="email" id='email' />
//           <div className="arrow-input"><img src={Arrow} alt="arrow"></img></div>
//         </div>

//       </div>
//     </div>
//   </div>
//   {/* </div> */}

//   <div className="ptp-1 col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center footer">
//     <img onClick={() => window.open("https://www.facebook.com/theestablishedcom", "_blank")}
//       src={FacebookIcon}
//       alt="FB icon"
//       className="mr-12 icon-height wp-10 cursor-pointer"
//     ></img>

//     <img onClick={() => window.open("https://instagram.com/theestablished_", "_blank")}
//       src={InstagramIcon}
//       alt="Insta icon"
//       className="mr-12 icon-height wp-10 cursor-pointer"
//     ></img>
//     <img
//       onClick={() => window.open("https://in.linkedin.com/company/theestablished", "_blank")}
//       src={LinkedinIcon}
//       alt="Linkedin icon"
//       className=" mr-12 icon-height wp-10 cursor-pointer"
//     ></img>
//     {/* <img
//     src={Twitter}
//     alt="Twitter icon"
//     className="icon-height wp-10 cursor-pointer"
//   ></img> */}
//   </div>
// </div>
// <div className="row ma-0 ptp-1 plrp-2 footer">
//   <div className=" col-lg-12 col-xl-12 footer-pad  col-md-12 col-sm-12 col-xs-12  text-list">
//     <div
//       className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//       onClick={() => props.history.push('/partner-with-us')}
//     >
//       PARTNER WITH US
//     </div>
//     <div
//       className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//       onClick={() => props.history.push('/news-letter')}
//     >
//       NEWSLETTER
//     </div>
//     <div
//       className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//       onClick={handleAboutUs}
//     >
//       ABOUT US
//     </div>
//     <div
//       className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//       onClick={handlePrivacy}
//     >
//       PRIVACY
//     </div>
//     <div
//       className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//     >
//       TERMS
//     </div>

//   </div>
// </div>
// {/* <div className="row ma-0 plrp-2">
// <div className="col-lg-12 col-xl-12  col-md-12 col-sm-12 col-xs-12 pbp-2 text-list">
// <div
// className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//     onClick={(e) => {
//       props.history.push("/contact-us");
//     }}
//     >
//     PRESS
//   </div>
//   <div
//     className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//     onClick={handlePrivacy}
//     >
//     PRIVACY
//   </div>
//   <div
//   className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//   onClick={(e) => {
//     props.history.push("/contact-us");
//   }}
//   >
//   TERMS
//   </div>

//   <div
//   className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//   >
//   AD CHOICES
//   </div>
//   <div
//     className="prp-2 remove-padding  cursor-pointer Biotif-Regular"
//   >
//     COOKIES
//   </div>
//   </div>

// </div> */}
// <div className="row ma-0">
//   <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 d-flex prp-5 copyrights  padding-bottom Biotif-Regular">
//     © The Established. 2021.
//   </div>
// </div>
// <div className="the-footer-established pbp-1"><img src={Established} alt="established-icon"></img></div>
// </div>
