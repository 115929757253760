import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { horizontalSmallAdHeight } from '../../helper/constants';

const GoogleAdvertisement = (props) => {
  const { dataAdSlot, styleHeight } = props;

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  let style = {
    display: "block",
  }

  if (styleHeight) {
    style.maxHeight = horizontalSmallAdHeight;
  }

  return (
    <>
    {styleHeight ? (
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client={`ca-${process.env.REACT_APP_GOOGLE_AD_PUBLISHER_ID}`}
        data-ad-slot={dataAdSlot}
        data-full-width-responsive="true"
      ></ins>
    ) : (
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client={`ca-${process.env.REACT_APP_GOOGLE_AD_PUBLISHER_ID}`}
        data-ad-slot={dataAdSlot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    )
    }
    </>
  );
};
export default withRouter(GoogleAdvertisement);
