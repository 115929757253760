import React, { Component } from "react";
import { Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const menu = (
  <Menu>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        2nd menu item (disabled)
      </a>
    </Menu.Item>
  </Menu>
);
const LeftMenu = (props) => {
  return (
    // <Menu mode="horizontal">
    <div className="nav-options">
      <Dropdown overlay={menu}>
        <a
          className="ant-dropdown-link cursor-pointer Biotif-Regular"
          onClick={() => props.history.push("/style")}
        >
          STYLE ↓
        </a>
      </Dropdown>
      <Dropdown overlay={menu}>
        <a
          className="ant-dropdown-link cursor-pointer Biotif-Regular"
          onClick={() => props.history.push("/self")}
        >
          SELF ↓
        </a>
      </Dropdown>
      <Dropdown overlay={menu}>
        <a
          className="ant-dropdown-link cursor-pointer Biotif-Regular"
          onClick={() => props.history.push("/community")}
        >
          COMMUNITY ↓
        </a>
      </Dropdown>
      <Dropdown overlay={menu}>
        <a
          className="ant-dropdown-link cursor-pointer Biotif-Regular"
          onClick={() => props.history.push("/culture")}
        >
          CULTURE ↓
        </a>
      </Dropdown>
    </div>
  );
};
export default withRouter(LeftMenu);
