import React,{useState,useEffect} from 'react';
import EstdFullFormat1 from '../ArticleLayout/EstdFullFormat1/EstdFullFormat1';
import EstdFullFormat2 from '../ArticleLayout/EstdFullFormat2/EstdFullFormat2';
import EstdFullFormat3 from '../ArticleLayout/EstdFullFormat3/EstdFullFormat3';
import EstdFullFormatDynamic from '../ArticleLayout/Articles-1/EstdFullFormatDynamic'
import EstdInterview from '../ArticleLayout/Articles-2/EstdInterview'
import EstdSlideShow from '../ArticleLayout/Article-5/EstdSlideShow';
import RandomLayout from '../ArticleLayout/RandomLayout';
import Header from '../../components/common/Header2/Header';
import Footer from '../../components/common/Footer/Footer';
// import RandomLayout from '../ArticleLayout/RandomLayout';
import { useParams, withRouter } from 'react-router-dom';
import Api from '../../helper/Api';

function Index(props) {
  const [propsData, setPropsData] = useState({
    story:props.data
  });
  console.log('in main article page',propsData);
  const [displayData, setDisplayData] = useState(false);
    useEffect(() => {
        if(Object.keys(props.data).length>=1){
            setPropsData({
                story:props.data
            });
            setDisplayData(true);
        }
    }, [props])

  return <div>
    <Header/>
    {
      displayData===true?propsData['story']['story-template']==='estd-full-format-1'?<EstdFullFormat1 data = {propsData}/>: propsData['story']['story-template']==='estd-full-format-2'? <EstdFullFormat2 data = {propsData}/>:propsData['story']['story-template']==='estd-full-format-3'?<EstdFullFormat3 data = {propsData}/>:propsData['story']['story-template']==='estd-full-format-dynamic'?<EstdFullFormatDynamic data = {propsData}/>:propsData['story']['story-template']==='estd-interview'?<EstdInterview data = {propsData}/>:propsData['story']['story-template']==='estd-slideshow'?<EstdSlideShow data = {propsData}/>:propsData['story']['story-template']==='text'?<RandomLayout data={propsData}/>:<div style={{height:'80vh'}} className='ptp-10'>select proper temp</div>:<div className='ptp-10' style={{height:'80vh'}}>Props didn't received any value</div>
    }
    <Footer/>
  </div>;
}

export default withRouter(Index)
// {
//   templetType==='story-template-4'?<EstdFullFormat1 data = {propsData}/>: templetType==='estd-full-format-2'? <Article5 data = {propsData}/>:templetType==='text'?<CommonArticle data={propsData}/>:""
// }