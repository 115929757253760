import React, { useEffect, useState } from 'react';
import './style.css';
import Api from '../../helper/Api';
import { imgBaseURL } from '../../helper/constants';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { subCatPageUrl } from '../../helper/constants'

const ReadMoreSection = (props) => {
    const { storyElement } = props
    console.log('rm storyele', storyElement)
    const [stories, setStories] = useState([]);
    const alternateHeroImg = (story) => {
        let heroImage = imgBaseURL + story['hero-image-s3-key']
        console.log('story images', story.alternative)
        if (story['alternative']) {
            if ('home' in story['alternative']) {
                if ('default' in story['alternative']['home']) {
                    console.log('alernative', story['alternative']['home'])
                    if ('hero-image' in story['alternative']['home']['default']) {
                        if (story['alternative']['home']['default']['hero-image']) {
                            if ('hero-image-s3-key' in story['alternative']['home']['default']['hero-image']) {
                                console.log(story['alternative']['home']['default']['hero-image']['hero-image-s3-key'])
                                heroImage = imgBaseURL + story['alternative']['home']['default']['hero-image']['hero-image-s3-key']
                            }
                        }
                    }
                }
            }
        }
        return heroImage
    }

    useEffect(() => {
        if (storyElement.length != 0) {
            const tempArr = []
            storyElement.map((story) => {
                Api('GET', `/api/v1/stories/${story['metadata']['linked-story-id']}`, {}).then((respone) => {
                    console.log('response', respone);
                    tempArr.push(respone.data);
                    setStories([...tempArr]);
                })
            })
        }
    }, [props.storyElement]);
    const handleIndividualStory = (a) => {
        props.history.push(`/${a['story']['slug']}`, a);
    }
    if (stories.length >= 1) {
        return <div> {/*className='mbp-10'*/}
            <div className='a4-border'></div>
            <div className='a4-read-more mbp-5'>
                <div className='a4-read-more-heading InterstateCompressed-Bold'>
                    READ MORE
                </div>
                <div className='a4-read-more-main'>
                    {
                        stories.map(a => (
                            <div className='a4-read-more-1 cursor-pointer'>
                                <Link to={{
                                    pathname: `/${a['story']['slug']}`,
                                    state: { story: a }
                                }}>
                                    <div className='a4-read-more-1-img'>
                                        <img src={alternateHeroImg(a.story)} alt="empty" />
                                    </div>
                                    <Link to={{
                                        pathname: subCatPageUrl[a['story']['sections'][0]['slug']].url,
                                        state: { key: subCatPageUrl[a['story']['sections'][0]['slug']].key }
                                    }}>
                                        <div className='a4-read-more-1-cat Biotif-Book'>
                                            {a['story']['sections'][0]['name'].toUpperCase()}
                                        </div>
                                    </Link>
                                    <div className='a4-read-more-1-title Biotif-SemiBold'>
                                        {a['story']['headline']}
                                    </div>
                                    <div className='a4-read-more-1-auth Biotif-Book'>
                                        By {a['story']['author-name']}
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>;
    }
    else {
        return <div></div>
    }
};

export default withRouter(ReadMoreSection);
