import React,{useState} from "react";
import StyleWrapper from "./style";
import { withRouter } from "react-router";
import Navbar from "../Navbar";
import logo from '../../../assets/images/LOGO.svg'
import logo2 from '../../../assets/images/LOGO2.svg'

const Header = (props) => {
  const [scrollValue,setScrollValue] = useState(false)
  function scrollFunction() {
if(document.getElementById("new-image")){
    if (document.body.scrollTop ===0 || document.documentElement.scrollTop === 0){
      document.getElementById("new-image").style.width = "40%";
    }
    if ((document.body.scrollTop < 50 || document.documentElement.scrollTop < 50)&&(document.body.scrollTop > 25 || document.documentElement.scrollTop > 25)) {
      document.getElementById("new-image").style.width = "20%";
    }
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      setScrollValue(true);
      document.getElementById("new-image").style.width = "100%";
    } else {
      setScrollValue(false);
      // document.getElementById("new-image").style.width = "40%";
    }
  }
}
  window.onscroll = function() {scrollFunction()};
  console.log('header',props.resp)
  return (
    <StyleWrapper>
       <div style ={{position:'relative'}}>
      <div className="ptbp-1 header-background-1">
        {scrollValue ? (
          <div>
            <div>
              <Navbar scrollData = {scrollValue} resp={props.resp}/>
            </div>

          </div>
        ) :
        <div>
          <div>
            <Navbar/>
          </div>
          {/* <div className="d-flex justify-content mtp-2">
            <img id="new-image" style={{width:"45%"}} src = {logo} alt="the-established"></img>
          </div> */}
        </div> }
      </div>
      </div>
    </StyleWrapper>
  );
};


export default (withRouter(Header));
