import React, { useState, useEffect } from "react";
import Header from "../../components/common/Header2/Header";
import { Tabs } from "antd";
import Api from "../../helper/Api";
import SectionWrapper from "./style";
import Footer from "../../components/common/Footer/Footer";
import style from "../../assets/images/title_svg_style.svg";
import SectionStoryDisplay from "../SectionStoryDisplay/SectionStoryDisplay";
import { Helmet } from "react-helmet";
import { categoryID } from "../../helper/constants";
import SubscribeSticky from "../../components/common/SubscribeSticky/SubscribeSticky";

const { TabPane } = Tabs;

const Style = (props) => {
  const [defaultActiveKey, setDefaultActiveKey] = useState(
    props.location.state && props.location.state.key
      ? props.location.state.key
      : "0"
  );
  const [storyDataToLaod, setStoryDataToLaod] = useState([]);
  const [storyMetaData, setStoryMetaData] = useState({
    from: 0,
    size: 0,
    total: 0,
  });
  const fetchStory = 45;
  useEffect(() => {
    window.scroll(0, 0);
    // Api('GET', '/api/v1/config', {}, {}).then((r) => {
    //     console.log('api config', r);
    // })
    if (defaultActiveKey === "1") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${categoryID.fashionID}&limit=47&offset=0`,
        {}
      ).then((r) => {
        setStoryDataToLaod(r.data.results.stories);
        setStoryMetaData({
          from: 0,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "3") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${categoryID.watchesID}&limit=47&offset=0`,
        {}
      ).then((r) => {
        setStoryDataToLaod(r.data.results.stories);
        setStoryMetaData({
          from: 0,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "2") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${categoryID.sneakersID}&limit=47&offset=0`,
        {}
      ).then((r) => {
        setStoryDataToLaod(r.data.results.stories);
        setStoryMetaData({
          from: 0,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "0") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${categoryID.styleID}&limit=47&offset=0`
      ).then((r) => {
        setStoryDataToLaod(r.data.results.stories);
        setStoryMetaData({
          from: 0,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    }
  }, [defaultActiveKey]);

  useEffect(() => {
    setDefaultActiveKey(
      props.location.state && props.location.state.key
        ? props.location.state.key
        : "0"
    );
  }, [props.location.state]);

  console.log("api call check out", storyMetaData);
  const getMoreStories = () => {
    if (defaultActiveKey === "1") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${
          categoryID.fashionID
        }&limit=${fetchStory}&offset=${
          storyMetaData.from + storyMetaData.size
        }`,
        {}
      ).then((r) => {
        const temp = r.data.results.stories;
        setStoryDataToLaod([...storyDataToLaod, ...temp]);
        setStoryMetaData({
          from: r.data.results.from,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "3") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${
          categoryID.watchesID
        }&limit=${fetchStory}&offset=${
          storyMetaData.from + storyMetaData.size
        }`,
        {}
      ).then((r) => {
        const temp = r.data.results.stories;
        setStoryDataToLaod([...storyDataToLaod, ...temp]);
        setStoryMetaData({
          from: r.data.results.from,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "2") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${
          categoryID.sneakersID
        }&limit=${fetchStory}&offset=${
          storyMetaData.from + storyMetaData.size
        }`,
        {}
      ).then((r) => {
        const temp = r.data.results.stories;
        setStoryDataToLaod([...storyDataToLaod, ...temp]);
        setStoryMetaData({
          from: r.data.results.from,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    } else if (defaultActiveKey === "0") {
      Api(
        "GET",
        `/api/v1/searchsectionid?section-id=${
          categoryID.styleID
        }&limit=${fetchStory}&offset=${storyMetaData.from + storyMetaData.size}`
      ).then((r) => {
        const temp = r.data.results.stories;
        setStoryDataToLaod([...storyDataToLaod, ...temp]);
        setStoryMetaData({
          from: r.data.results.from,
          size: r.data.results.size,
          total: r.data.results.total,
        });
      });
    }
  };

  const KEYS = {
    1: "fashion",
    2: "sneakers",
    3: "watches",
  };

  function callback(key) {
    if (key !== "0") props?.history.push(`/style/${KEYS[key]}`);
    else props?.history.push(`/style`);

    setDefaultActiveKey(key);
  }

  return (
    <SectionWrapper>
      <Helmet defer={false}>
        <title>
          Latest Fashion Trends 2022 | Fashion Sneakers | Luxury Watches
        </title>
        <meta
          name="description"
          content="Get to know about Latest fashion trends only with The Established. Subscribe to The Established newsletter so never miss any updates."
        />
      </Helmet>
      {/* Google Tag Manager (gtag.js) */}
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
      </Helmet>
      <Header />
      <div className="">
        <div className="main-cat-div">
          <div>
            <Tabs
              className="Biotif-Bold"
              activeKey={defaultActiveKey}
              onChange={callback}
            >
              <div style={{ display: "none" }}>
                <TabPane className="d-none" tab="FASHION" key="0"></TabPane>
              </div>
              <TabPane tab="FASHION" key="1"></TabPane>
              <TabPane tab="SNEAKERS" key="2"></TabPane>
              <TabPane tab="WATCHES" key="3"></TabPane>
            </Tabs>
          </div>

          <div className="style-heading InterstateCompressed-Bold mtp-5">
            <img
              src={style}
              alt="style logo"
              className="cursor-pointer"
              onClick={() => setDefaultActiveKey("0")}
            />
          </div>

          <SectionStoryDisplay stories={storyDataToLaod} cat="style" />
          {storyMetaData.from + storyMetaData.size < storyMetaData.total ? (
            <div className="d-flex justify-content Biotif-Regular mtp-5 mbp-8 section-read-more">
              <button
                className="read-more-btn"
                onClick={() => {
                  getMoreStories();
                }}
              >
                SHOW MORE →
              </button>
            </div>
          ) : (
            <div className="mbp-10"></div>
          )}
        </div>
      </div>
      {/* <Subscribe /> */}
      <SubscribeSticky />

      <Footer />
    </SectionWrapper>
  );
};

export default Style;
