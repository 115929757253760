import React from "react";
import community from "../../assets/images/title_svg_community.svg";
import { imgBaseURL } from '../../helper/constants';
import { useMediaQuery } from "react-responsive";
import CommunityWrapper from "./style";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { subCatPageUrl } from '../../helper/constants'


const Community = (props) => {
  const Tablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const resp = props.resp
  const handleIndividualStory = (a) => {
    console.log('onclick', a['story']['sections'][0]);
    props.history.push(`/${a['story']['slug']}`, a);
  }
  if (resp.length) {
    return (
      <CommunityWrapper>
        <div className="mlrp-10 community-main-div">
          <div className="community-title InterstateCompressed-Bold">
            <img src={community} alt="community" className="cursor-pointer" onClick={() => { props.history.push('/community') }} />
          </div>
          <div className="image-position">
            <div className="grid ">
              {resp.slice(0, 5).map((a, index) => (
                <div className={`grid-item-${index + 1} grid-item-1-${index + 1} cursor-pointer`}>
                  <Link to={{
                    pathname: `/${a['story']['slug']}`,
                    state: { story: a }
                  }}>

                    <img
                      style={{ objectFit: "cover" }}
                      src={`${imgBaseURL}${a['story']['hero-image-s3-key']}`}
                      width="100%"
                      alt=""
                    />
                    <Link to={{
                      pathname: subCatPageUrl[a['story']['sections'][0]['slug']].url,
                      state: { key: subCatPageUrl[a['story']['sections'][0]['slug']].key }
                    }}>
                      <div className="sub-category Biotif-Book">
                        {a['story']['sections'][0]['name'].toUpperCase()}
                      </div>
                    </Link>
                    <div className="question Biotif-SemiBold ">
                      {a['story']['headline']}
                    </div>
                    <div className="author Biotif-Book ">
                      By {a['story']['author-name']}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className={Tablet ? "grid-2 pt-10" : "grid-2 pt-10"}>
              {resp.slice(5, 8).map((a, index) => (
                <div className={`grid-item-${index + 1} grid-item-2-${index + 1}`} style={{ cursor: 'pointer' }}>
                  <Link to={{
                    pathname: `/${a['story']['slug']}`,
                    state: { story: a }
                  }}>

                    <img
                      style={{ objectFit: "cover" }}
                      src={`${imgBaseURL}${a['story']['hero-image-s3-key']}`}
                      width="100%"
                      alt=""
                    />
                    <Link to={{
                      pathname: subCatPageUrl[a['story']['sections'][0]['slug']].url,
                      state: { key: subCatPageUrl[a['story']['sections'][0]['slug']].key }
                    }}>
                      <div className="sub-category Biotif-Book">
                        {a['story']['sections'][0]['name'].toUpperCase()}
                      </div>
                    </Link>
                    <div className="question Biotif-SemiBold">
                      {a['story']['headline']}
                    </div>
                    <div className="author Biotif-Book ">
                      By {a['story']['author-name']}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <Link to={{
            pathname: `/${resp[8]['story']['slug']}`,
            state: { story: resp[8] }
          }}>
            <img width="100%" src={`${imgBaseURL}${resp[8]['story']['hero-image-s3-key']}`} alt='group-photo-image' className='cursor-pointer' />
            <div className='image-data'>

              <div className='image-title Biotif-Regular cursor-pointer'>
              <Link to={{
                  pathname: subCatPageUrl[resp[8]['story']['sections'][0]['slug']].url,
                  state: { key: subCatPageUrl[resp[8]['story']['sections'][0]['slug']].key }
                }}>
                  <span className='image-title Biotif-Regular cursor-pointer'>
                {resp[8]['story']['sections'][0]['name'].toUpperCase()}
                  </span>
                </Link>
                 – By <em>{resp[8]['story']['author-name']}</em>
              </div>
              <div className='image-text Biotif-SemiBold cursor-pointer'>
                {resp[8]['story']['headline']}
              </div>
            </div>
            </Link>
        </div>
      </CommunityWrapper>
    );
  }
  else {
    return <div></div>
  }
};
export default (withRouter)(Community);
