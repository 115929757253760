// new ads
import three60WebAd1 from "../assets/images/360ONE_Web_Banners-3.png";
import three60WebAd2 from "../assets/images/360ONE_Web_Banners-5.png";
import three60MobAd1 from "../assets/images/360ONE_Web_Banners-2.png";
import three60MobAd2 from "../assets/images/360ONE_Web_Banners-1.png";

// home ads
import paneriWebAd1 from "../assets/images/R&M Home1.jpg";
import timeWebad1 from "../assets/images/TA Home3.gif";
import paneriMobAd1 from "../assets/images/R&M Sq Home1.jpg";
import timeMobad1 from "../assets/images/TA Sq.gif";

//bmsadds
import bmsWebAd from "../assets/images/bmsweb.png";
import bmsMobAd from "../assets/images/bmsmob.png";

//subscribe ads
import SubscribeWebAdd from "../assets/images/subc_web.jpg";
import SubscribeWebMob from "../assets/images/subc_mob.jpg";

//insta ads
import InstaSubscribeWebAdd from "../assets/images/insta_subs_web.jpg";
import InstaSubscribeWebMob from "../assets/images/insta_subs_mob.jpg";

// category ads
import StyleAd from "../assets/images/R&M Style.jpg";
import CultureAd from "../assets/images/R&M Culture.jpg";
import StyleAdSq1 from "../assets/images/R&M_Sq Style.jpg";
import CultureAdSq3 from "../assets/images/R&M_Sq Culture.jpg";

// home ads obj
export const homeAd1 = {
  img: SubscribeWebAdd,
  // img: WAd1,
  linkStatus: "external",
  url: "https://www.theestablished.com/news-letter",
  // url: "https://luxurylifestyleweekend.com/",
};
export const homeAdSq1 = {
  img: SubscribeWebMob,
  // img: SQAd1,
  linkStatus: "external",
  url: "https://www.theestablished.com/news-letter",
  // url: "https://luxurylifestyleweekend.com/",
};

export const homeAd2 = {
  // img: HomeAd2,
  img: three60WebAd1,
  // linkStatus: "internal",
  // url: "/news-letter",
  linkStatus: "external",
  url: "https://www.360.one/",
};
export const homeAdSq2 = {
  // img: HomeAdSq2,
  // linkStatus: "internal",
  // url: "/news-letter",
  img: three60MobAd1,
  // linkStatus: "internal",
  // url: "/news-letter",
  linkStatus: "external",
  url: "https://www.360.one/",
};

export const homeAd3 = {
  img: timeWebad1,
  // img: bmsWebAd,
  linkStatus: "external",
  url: "https://timeavenue.com/",
  // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284",
};
export const homeAdSq3 = {
  img: timeMobad1,
  // img: bmsMobAd,
  linkStatus: "external",
  url: "https://timeavenue.com/",
  // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284",
};

export const homeAd4 = {
  // img: HomeAd4,
  img: InstaSubscribeWebAdd,
  linkStatus: "external",
  url: "https://www.instagram.com/theestablished_",
};

export const homeAdSq4 = {
  // img: HomeAdSq4,
  img: InstaSubscribeWebMob,
  linkStatus: "external",
  url: "https://www.instagram.com/theestablished_",
};

// category ads obj
export const styleAd = { ...homeAd1 };
//  {
//   img: StyleAd,
//   // img: WAd1,
//   linkStatus: "external",
//   url: "https://www.randmluxury.com/",
//   // url: "https://luxurylifestyleweekend.com/",
// };

export const styleAdSq1 = { ...homeAdSq1 };
// {
//   img: StyleAdSq1,
//   // img: SQAd1,
//   linkStatus: "external",
//   url: "https://www.randmluxury.com/",
//   // url: "https://luxurylifestyleweekend.com/",
// };

// const style subads
export const styleSubAds = [
  // {
  //   webViewImg: three60WebAd1,
  //   mobViewImg: three60MobAd1,
  //   linkStatus: "external",
  //   url: "https://www.360.one/",
  // },
  {
    webViewImg: timeWebad1,
    mobViewImg: timeMobad1,
    linkStatus: "external",
    url: "https://timeavenue.com/",
    // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284",
  },
  {
    webViewImg: InstaSubscribeWebAdd,
    mobViewImg: InstaSubscribeWebMob,
    linkStatus: "external",
    url: "https://www.instagram.com/theestablished_",
  },
  {
    webViewImg: SubscribeWebAdd,
    mobViewImg: SubscribeWebMob,
    linkStatus: "external",
    url: "https://www.theestablished.com/news-letter",
  },
  // {
  //   webViewImg: three60WebAd2,
  //   mobViewImg: three60MobAd2,
  //   linkStatus: "external",
  //   url: "https://www.360.one/",
  // },
];

export const selfAd = {
  img: timeWebad1,
  // img: bmsWebAd,
  linkStatus: "external",
  url: "https://timeavenue.com/",
  // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284", // "https://luxurylifestyleweekend.com/",
};
export const selfAdSq2 = {
  // img: bmsMobAd,
  img: timeMobad1,
  linkStatus: "external",
  url: "https://timeavenue.com/",
  // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284", //"https://luxurylifestyleweekend.com/",
};

export const selfSubAds = [
  // {
  //   webViewImg: three60WebAd2,
  //   mobViewImg: three60MobAd2,
  //   linkStatus: "external",
  //   url: "https://www.360.one/",
  // },
  // {
  //   webViewImg: paneriWebAd1,
  //   mobViewImg: paneriMobAd1,
  //   linkStatus: "external",
  //   url: "https://www.randmluxury.com/",
  // },
  {
    webViewImg: SubscribeWebAdd,
    mobViewImg: SubscribeWebMob,
    linkStatus: "external",
    url: "https://www.theestablished.com/news-letter",
  },
  {
    webViewImg: InstaSubscribeWebAdd,
    mobViewImg: InstaSubscribeWebMob,
    linkStatus: "external",
    url: "https://www.instagram.com/theestablished_",
  },
  {
    webViewImg: timeWebad1,
    mobViewImg: timeMobad1,
    linkStatus: "external",
    url: "https://timeavenue.com/",
    // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284",
  },
  // {
  //   webViewImg: three60WebAd1,
  //   mobViewImg: three60MobAd1,
  //   linkStatus: "external",
  //   url: "https://www.360.one/",
  // },
];

export const cultureAd = { ...homeAd1 };
// {
//   img: CultureAd,
//   // img: WAd1,
//   linkStatus: "external",
//   url: "https://www.randmluxury.com/",
//   // url: "https://luxurylifestyleweekend.com/",
// };
export const cultureAdSq3 = { ...homeAdSq1 };
// {
//   img: CultureAdSq3,
//   // img: SQAd1,
//   linkStatus: "external",
//   url: "https://www.randmluxury.com/",
//   // url: "https://luxurylifestyleweekend.com/",
// };

export const cultureSubAds = [
  // {
  //   webViewImg: three60WebAd1,
  //   mobViewImg: three60MobAd1,
  //   linkStatus: "external",
  //   url: "https://www.360.one/",
  // },
  {
    webViewImg: timeWebad1,
    mobViewImg: timeMobad1,
    linkStatus: "external",
    url: "https://timeavenue.com/",
    // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284",
  },
  // {
  //   webViewImg: CultureAd,
  //   mobViewImg: CultureAdSq3,
  //   linkStatus: "external",
  //   url: "https://www.randmluxury.com/",
  // },
  {
    webViewImg: InstaSubscribeWebAdd,
    mobViewImg: InstaSubscribeWebMob,
    linkStatus: "external",
    url: "https://www.instagram.com/theestablished_",
  },
  {
    webViewImg: SubscribeWebAdd,
    mobViewImg: SubscribeWebMob,
    linkStatus: "external",
    url: "https://www.theestablished.com/news-letter",
  },

  // {
  //   webViewImg: three60WebAd2,
  //   mobViewImg: three60MobAd2,
  //   linkStatus: "external",
  //   url: "https://luxurylifestyleweekend.com/",
  // },
];

export const communityAd = {
  img: timeWebad1,
  // img: bmsWebAd,
  linkStatus: "external",
  url: "https://timeavenue.com/",
  // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284", // "https://luxurylifestyleweekend.com/",
};

export const communityAdSq4 = {
  img: timeMobad1,
  // img: bmsMobAd,
  linkStatus: "external",
  url: "https://timeavenue.com/",
  // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284", // "https://luxurylifestyleweekend.com/",
};

export const communitySubAds = [
  // {
  //   webViewImg: three60WebAd2,
  //   mobViewImg: three60MobAd2,
  //   linkStatus: "external",
  //   url: "https://www.360.one/",
  // },
  // {
  //   webViewImg: paneriWebAd1,
  //   mobViewImg: paneriMobAd1,
  //   linkStatus: "external",
  //   url: "https://www.randmluxury.com/",
  // },
  {
    webViewImg: SubscribeWebAdd,
    mobViewImg: SubscribeWebMob,
    linkStatus: "external",
    url: "https://www.theestablished.com/news-letter",
  },
  {
    webViewImg: InstaSubscribeWebAdd,
    mobViewImg: InstaSubscribeWebMob,
    linkStatus: "external",
    url: "https://www.instagram.com/theestablished_",
  },
  {
    webViewImg: timeWebad1,
    mobViewImg: timeMobad1,
    linkStatus: "external",
    url: "https://timeavenue.com/",
    // url: "https://in.bookmyshow.com/events/india-design-id-mumbai-2024/ET00404284",
  },
  // {
  //   webViewImg: three60WebAd1,
  //   mobViewImg: three60MobAd1,
  //   linkStatus: "external",
  //   url: "https://www.360.one/",
  // },
];

export const baseUrl = "https://theestablished.qtstage.io";
// export const imgBaseURL = 'https://gumlet.assettype.com/';
export const imgBaseURL = "https://gumlet.assettype.com/";
export const gaTag = "GTM-WWGMNM2";

export const categoryID = {
  styleID: "38240",
  fashionID: "38242",
  sneakersID: "38244",
  watchesID: "38245",
  selfID: "38237",
  beautyID: "38238",
  healthID: "38239",
  communityID: "38226",
  consciousLivingID: "38228",
  businessID: "38227",
  identityID: "38229",
  viewpointID: "38230",
  cultureID: "38231",
  autoID: "38232",
  entertainmentID: "38233",
  gamingID: "38234",
  livingID: "38235",
};
//
export const subCatPageUrl = {
  fashion: {
    url: "/style",
    key: "1",
  },
  sneakers: {
    url: "/style",
    key: "2",
  },
  watches: {
    url: "/style",
    key: "3",
  },
  beauty: {
    url: "/self",
    key: "1",
  },
  health: {
    url: "/self",
    key: "2",
  },
  "conscious-living": {
    url: "/community",
    key: "2",
  },
  business: {
    url: "/community",
    key: "1",
  },
  identity: {
    url: "/community",
    key: "3",
  },
  viewpoint: {
    url: "/community",
    key: "4",
  },
  living: {
    url: "/culture",
    key: "4",
  },
  culture: {
    url: "/culture",
    key: "0",
  },
  auto: {
    url: "/culture",
    key: "1",
  },
  gaming: {
    url: "/culture",
    key: "3",
  },
  entertainment: {
    url: "/culture",
    key: "2",
  },
  style: {
    url: "/style",
    key: "0",
  },
  self: {
    url: "/self",
    key: "0",
  },
  community: {
    url: "/community",
    key: "0",
  },
};

export const maxContentAd = 4;
export const horizontalSmallAdHeight = "120px";
export const verticalAdContentLength = 750;
