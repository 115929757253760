import React, { useEffect, useMemo, useState } from "react";
import Twitter from "../../../assets/images/Asset3.png";
import Instagram from "../../../assets/images/Asset5.png";
import Facebook from "../../../assets/images/Asset6.png";
import ArticleWrapper from "./style";
import Header from "../../../components/common/Header2/Header";
import Footer from "../../../components/common/Footer/Footer";
import EstdFullFormatDynamicCard1 from "./EstdFullFormatDynamicCard1";
import EstdFullDynamicFormatCard2 from "./EstdFullDynamicFormatCard2";
import ReadMoreSection from "../ReadMoreSection";
import { imgBaseURL } from "../../../helper/constants";
import EstdFullFormat1Card2 from "../EstdFullFormat1/EstdFullFormat1card2";
import EstdFullFormatDynamicCard01 from "./ESTDFullDynamicFormatCard01";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { generateSchemaData } from "../../../helper/schemaData";

const EstdFullFormatDynamic = (props) => {
  const data = props.data;
  const cardType = [];
  const cards = data["story"]["cards"];
  console.log("cards", cards);
  const [displayFlag, setDisplayFlag] = useState(false);
  const [cardtype, setcardtype] = useState([]);
  var firstStory = true;
  useEffect(() => {
    window.scroll(0, 0);
    data["story"]["cards"].map((card, index) => {
      if ("alternateheroimage" in card.metadata.attributes) {
        console.log("wroking alternateheroimage");
      } else {
        card["story-elements"].map((element) => {
          if (
            element.type === "image" &&
            element.subtype === null &&
            firstStory
          ) {
            cardType[index] = "card01";
            firstStory = false;
          } else if (element.type === "image" && element.subtype === null) {
            cardType[index] = "card1";
          } else if (
            element.type === "composite" &&
            element.subtype === "image-gallery"
          ) {
            cardType[index] = "card2";
          } else if (
            element.type === "text" &&
            element.subtype === "also-read"
          ) {
            cardType[index] = "also-read";
          }
          // else{
          //     cardType[index]='card1';
          // }
        });
        if (typeof cardType[index] === "undefined") {
          cardType[index] = "card3";
        }
      }
    });
    console.log("cardtype", cardType);
    setcardtype(cardType);
    setDisplayFlag(true);
  }, []);

  const heroData = {
    img: "https://gumlet.assettype.com/" + data["story"]["hero-image-s3-key"],
    imgTitle: data["story"]["hero-image-caption"],
    cat: data["story"]["sections"][0]["name"].toUpperCase(),
    title: data["story"]["headline"],
    auth: data["story"]["author-name"],
    authId: data["story"]["author-id"],
  };
  if ("home" in data["story"]["alternative"]) {
    if ("default" in data["story"]["alternative"]["home"]) {
      console.log("alernative", data["story"]["alternative"]["home"]);
      if ("hero-image" in data["story"]["alternative"]["home"]["default"]) {
        if (
          data["story"]["alternative"]["home"]["default"]["hero-image"] != null
        ) {
          if (
            "hero-image-s3-key" in
            data["story"]["alternative"]["home"]["default"]["hero-image"]
          ) {
            heroData.img =
              imgBaseURL +
              data["story"]["alternative"]["home"]["default"]["hero-image"][
                "hero-image-s3-key"
              ];
          }
        }
      }
    }
  }
  const metaDataStory = {
    title: heroData.title,
    description: heroData.title,
    keywords: "The Established",
  };
  if ("seo" in data["story"]) {
    console.log("in seo");
    if ("meta-title" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-title"].length > 1) {
        metaDataStory.title = data["story"]["seo"]["meta-title"];
        console.log("in seo meta");
      } else {
        metaDataStory.title = heroData.title;
      }
    }
    if ("meta-description" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-description"].length > 1) {
        metaDataStory.description = data["story"]["seo"]["meta-description"];
      } else {
        metaDataStory.title = heroData.title;
        console.log("else in seo meta");
      }
    }
    if ("meta-keywords" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-keywords"].length > 1) {
        metaDataStory.keywords =
          data["story"]["seo"]["meta-keywords"].toString();
      }
    }
  } else {
    metaDataStory.title = heroData.title;
    metaDataStory.description = heroData.title;
  }

  const getSchemaData = useMemo(() => {
    return generateSchemaData(props?.data, metaDataStory);
  }, [props.data]);

  return (
    <ArticleWrapper>
      <Helmet defer={false}>
        <title>{metaDataStory.title}</title>
        <meta
          name="description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />

        <meta
          property="twitter:url"
          content={window.location.href}
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <link
          rel="canonical"
          href={
            data["story"]["canonical-url"]
              ? data["story"]["canonical-url"]
              : window.location.href
          }
          data-react-helmet="true"
        />
        <meta name="author" content={heroData.auth} data-react-helmet="true" />
        <meta name="keywords" content={metaDataStory.keywords} />
      </Helmet>
      {/* Google Tag Manager (gtag.js) */}
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
        {props?.data && (
          <script type="application/ld+json">
            {JSON.stringify(getSchemaData)}
          </script>
        )}
      </Helmet>
      {/* <Header /> */}
      <div className="estd-full-format-2-web">
        <div className="effd-main-img pbp-2">
          <img width="100%" src={heroData.img} alt="main-image"></img>
        </div>

        <div className="style-title pbp-2">{heroData.cat}</div>
        <div className="HeldaneDisplay-Regular style-question pbp-2">
          <h1 className="internal-div HeldaneDisplay-Regular">
            {heroData.title}
          </h1>
        </div>
        <Link
          to={{
            pathname: `/author/${heroData.authId}/${heroData.auth
              ?.replace(/ /g, "-")
              .toLowerCase()}`,
            state: { story: data, authId: heroData.authId },
          }}
        >
          <div className="style-author">By {heroData.auth}</div>
        </Link>
      </div>

      <div className="estd-full-format-2-mobile">
        <div className="style-title pbp-2">{heroData.cat}</div>
        <div className="HeldaneDisplay-Regular style-question pbp-2">
          <h1 className="internal-div HeldaneDisplay-Regular">
            {heroData.title}
          </h1>
        </div>
        <Link
          to={{
            pathname: `/author/${heroData.authId}/${heroData.auth
              ?.replace(/ /g, "-")
              .toLowerCase()}`,
            state: { story: data, authId: heroData.authId },
          }}
        >
          <div className="style-author">By {heroData.auth}</div>
        </Link>
        <div className="effd-main-img pbp-2">
          <img width="100%" src={heroData.img} alt="main-image"></img>
        </div>
      </div>

      {displayFlag &&
        cardtype.map((card, index) =>
          card === "card01" ? (
            <>
              <EstdFullFormatDynamicCard01
                heroData={heroData}
                metaDataStory={metaDataStory}
                cards={cards[index]}
                publishedDate={data["story"]["published-at"]}
              />
            </>
          ) : card === "card1" ? (
            <>
              <EstdFullFormatDynamicCard1 cards={cards[index]} />
            </>
          ) : card === "card2" ? (
            <>
              <EstdFullDynamicFormatCard2 cards={cards[index]} />
            </>
          ) : card === "card3" ? (
            <>
              <EstdFullFormat1Card2 cards={cards[index]} />
            </>
          ) : (
            ""
          )
        )}
      {displayFlag &&
        cardtype.map((card, index) =>
          card === "also-read" ? (
            <>
              <ReadMoreSection storyElement={cards[index]["story-elements"]} />
            </>
          ) : (
            ""
          )
        )}
      {/* <div className='row ma-0 ptp-5 pbp-5 plp-10 prp-10'>
                <div className='read-more-new1 InterstateCompressed-Bold'>READ MORE</div>
                <div className='read-more-grid'>
                    {itemsArr.map(result=>(
                        <div>
                            <img width="100%" src = {result.img}></img>
                            <div className='read-more-subc mtp-3 mbp-1'>{result.subc}</div>
                            <div className='Biotif-SemiBold read-more-desc mbp-2'>{result.title}</div>
                            <div className='read-more-subc Biotif-Book mbp-5'>{result.auth}</div>
                        </div>
                    ))}
                </div>
            </div> */}
      {/* <Footer /> */}
    </ArticleWrapper>
  );
};

export default EstdFullFormatDynamic;
