import React from "react";
import {
  imgBaseURL,
  maxContentAd,
  horizontalSmallAdHeight,
} from "../../../helper/constants";
import GoogleAdvertisement from "../../Advertise/GoogleAdvertisement";

const EstdFullFormat2card2 = (props) => {
  const { cards, isLastCardType, adCounter, increaseAdCounter } = props;
  // console.log('cards', cards, props)
  const storyElement = cards["story-elements"];

  let localAdCounter = adCounter;

  if (storyElement) {
    return (
      <div className="a5-p2">
        {storyElement.map((element, index) =>
          element.type === "text" ? (
            <>
              <div
                className="a5-p2-p2"
                dangerouslySetInnerHTML={{ __html: element.text }}
              ></div>
              {/* {((!(storyElement.length == index + 1) || (storyElement.length == index + 1 && !isLastCardType)) && localAdCounter < maxContentAd) &&
                        <>
                            <div className='d-none'>{localAdCounter++}</div>
                            {increaseAdCounter()}
                            <div className="web-view-ad-img web-view-ad-img-horizontal-small">
                                <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal}
                                    styleHeight={horizontalSmallAdHeight} />
                            </div>
                            <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
                                <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
                            </div>
                        </>
                        } */}
            </>
          ) : element.type === "image" ? (
            <div>
              <div className="a5-three-img">
                <div className="a5-three-img-1">
                  <img src={`${imgBaseURL}${element["image-s3-key"]}`} alt="" />
                </div>
                <div className="a5-three-img-1">
                  <img src={`${imgBaseURL}${element["image-s3-key"]}`} alt="" />
                </div>
                <div className="a5-three-img-1">
                  <img src={`${imgBaseURL}${element["image-s3-key"]}`} alt="" />
                </div>
              </div>
              <div
                className="a5-three-img-cap Heldane-Text-Regular"
                dangerouslySetInnerHTML={{ __html: element["title"] }}
              ></div>
            </div>
          ) : element.subtype === "image-gallery" ? (
            <div className="a5-three-img">
              {element["story-elements"].map((imageData) => (
                <div className="a5-three-img-1">
                  <img src={`${imgBaseURL}${imageData["image-s3-key"]}`}></img>
                  <div
                    className="a5-three-img-cap"
                    dangerouslySetInnerHTML={{ __html: imageData["title"] }}
                  ></div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default EstdFullFormat2card2;
