import moment from "moment";
import { imgBaseURL } from "./constants";

const findStoryImages = (obj, keyToFind) => {
  const results = [];

  const recurse = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === keyToFind) {
          results.push(`${imgBaseURL}${obj[key]}`);
        }
        if (typeof obj[key] === "object" && obj[key] !== null) {
          recurse(obj[key]);
        }
      }
    }
  };

  recurse(obj);
  return results;
};

export const generateSchemaData = (data, metaDataStory) => {
  let schemaData = "";
  if (data.story) {
    const storyImages = findStoryImages(data.story.cards, "image-s3-key");
    schemaData = {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": window.location.href,
      },
      headline: data["story"]["headline"],
      description: metaDataStory.description,
      image: [
        `${imgBaseURL}${data["story"]["hero-image-s3-key"]}`,
        ...storyImages,
      ],
      author: {
        "@type": "Person",
        name: data["story"]["author-name"],
        url: `https://www.theestablished.com/author/${
          data["story"]["author-id"]
        }/${data["story"]["author-name"]?.replace(/ /g, "-").toLowerCase()}`,
      },
      publisher: {
        "@type": "Organization",
        name: "The Established",
        logo: {
          "@type": "ImageObject",
          url: "https://www.theestablished.com/estd-favicon.png",
        },
      },
      datePublished: moment(data["story"]["published-at"]).format("YYYY-MM-DD"),
      dateModified: moment(data["story"]["updated-at"]).format("YYYY-MM-DD"),
    };
  }
  return schemaData;
};

export const getOrgSchema = (type = "HOME") => {
  return {
    "@context": "http://schema.org",
    "@type": "Organization",
    legalName: "The Established",
    url: window.location.href,
    logo: "https://www.theestablished.com/estd-favicon.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "022-1111-111",
      contactType: "Postal Address",
    },
    address: {
      "@type": "PostalAddress",
      streetAddress:
        "Unit at 1st Floor, Estate, Mathuradas Mill Compound, D-2, NM Joshi Marg, Lower Parel, ,",
      addressLocality: "Mumbai",
      addressRegion: "Maharashtra",
      postalCode: "400013",
      addressCountry: "India",
    },
    sameAs: [
      "https://www.facebook.com/theestablishedcom",
      "https://www.instagram.com/theestablished_/",
      "https://www.youtube.com/@TheEstablished11",
      "https://in.linkedin.com/company/theestablished",
    ],
  };
};
