import React from 'react';

const EstdFullDynamicFormatCard2 = (props) => {
    const { cards } = props
    const bgColour = ''
    if ('cardbackgroungcolour' in cards.metadata.attributes) {
        console.log('bg color is presend', cards.metadata.attributes.cardbackgroungcolour[0])
    }
    const storyElement = cards['story-elements']
    if (storyElement) {
        return <div>
            <div className='black-bg black-bg-m-p row row-data ma-0  pbp-10'>
                <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12 mtp-10 d-flex align-center p-0' >
                    <div className='style-grid-new ffd-b-img'>
                        {
                            storyElement.map((element) => (
                                element.subtype === 'image-gallery' ? element['story-elements'].map(imageData => (
                                    <div>
                                        <div>
                                            <img width="100%" src={`https://gumlet.assettype.com/${imageData['image-s3-key']}`}></img>
                                            <div className='image-text HeldaneDisplay-Regular' dangerouslySetInnerHTML={{ __html: imageData['title'] }}></div>
                                        </div>
                                    </div>
                                )) : ""
                            ))
                        }
                    </div>
                </div>
                <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12 mtp-10 d-flex align-center p-0'>
                    {
                        storyElement.map((element) => (
                            element.type === 'text' ? <div className='row-2-description-part pbp-5' dangerouslySetInnerHTML={{ __html: element.text }}>
                            </div> : ""
                        ))
                    }
                </div>
            </div>

        </div>;
    } else return <div></div>
};

export default EstdFullDynamicFormatCard2;
