import "./App.css";
import Routes from "../src/routes/routes";
import "../src/assets/scss/app.scss";
import Modal from "react-modal";
import React, { useEffect } from "react";
import Mailchimp from "./views/Mailchimp/Mailchimp";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "universal-cookie";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "transparent",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function App() {
  const cookies = new Cookies();
  // React.useEffect(() => {
  //   // fetch("/api/v1/stories").then((res) => console.log(res.data));
  //   axios.get('/api/v1/stories').then(r=>{
  //     console.log('hello',r);
  //   })

  // }, []);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const checkCookie = (value) => {
    return cookies.get(value);
  };

  useEffect(() => {
    const overlay = document.getElementById("maa-baap");

    if (modalIsOpen) {
      overlay.addEventListener("click", disableClick);
    } else {
      overlay.removeEventListener("click", disableClick);
    }
    return () => {
      overlay.removeEventListener("click", disableClick);
    };
  }, [modalIsOpen]);

  useEffect(() => {
    if (typeof checkCookie("ReactModalPopup") == "undefined") {
      openModal();
    } else {
      const oneYearInSeconds = 365 * 24 * 60 * 60; // 1 year in seconds

      cookies.set(
        "ReactModalPopup",
        { shuoldShow: false },
        {
          path: "/",
          maxAge: oneYearInSeconds, // Use maxAge for more reliability in Safari
        }
      );
    }
  }, []);

  const disableClick = (e) => {
    e.stopPropagation();
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };
  const enableScroll = () => {
    document.body.style.overflowY = "scroll";
  };

  function openModal() {
    // e.preventDefault();
    setTimeout(() => {
      setIsOpen(true);
    }, 45000);
  }

  function closeModal(isRefresh = "refresh") {
    // e.preventDefault();
    setIsOpen(false);
    if (isRefresh === "refresh") {
      window.location.pathname = "/subscribed";
    }
  }
  var DEBUG = false;
  // ENABLE/DISABLE Console Logs using this
  if (!DEBUG) {
    console.log = function () {};
  }
  return (
    <div id="maa-baap">
      <div className="modal-div" style={{ zIndex: 100 }}>
        <Modal
          isOpen={modalIsOpen}
          //onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          preventScroll={true}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <div
            className="d-flex justify-content-end cursor-pointer"
            onClick={() => closeModal("noRefresh")}
          >
            <AiOutlineClose size={"2em"} />
          </div>
          <Mailchimp closeModal={closeModal} />
        </Modal>
      </div>
      <Routes />
    </div>
  );
}

export default App;
