import React, { useEffect, useMemo, useState } from "react";
import StyleArticle from "./style";
import WhatsApp from "../../../assets/images/whatsapplogo.svg";
import Instagram from "../../../assets/images/lilogopink.svg";
import Facebook from "../../../assets/images/fblogopink.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import Header from "../../../components/common/Header2/Header";
import Footer from "../../../components/common/Footer/Footer";
import EstdInterviewCard1 from "./EstdInterviewCard1";
import EstdInterviewCard2 from "./EstdInterviewCard2";
import ReadMoreSection from "../ReadMoreSection";
import { imgBaseURL } from "../../../helper/constants";
import { Helmet } from "react-helmet";
import moment from "moment";
import GoogleAdvertisement from "../../Advertise/GoogleAdvertisement";
import { Link } from "react-router-dom";
import { generateSchemaData } from "../../../helper/schemaData";

const EstdInterview = (props) => {
  const data = props.data;
  const cardType = [];
  const cards = data["story"]["cards"];
  const [cardBgColor, setCardBgColor] = useState([]);
  console.log("cards", cards);
  const [displayFlag, setDisplayFlag] = useState(false);
  const [cardtype, setcardtype] = useState([]);
  useEffect(() => {
    window.scroll(0, 0);
    let tempcont = 0;
    cards.map((card, index) => {
      console.log("alternateheroimage" in card.metadata.attributes);
      if (Object.keys(card.metadata.attributes).length >= 1) {
        if ("alternateheroimage" in card.metadata.attributes) {
          if (card.metadata.attributes.alternateheroimage[0] === "yes") {
            console.log("hero image");
          }
        }
        if ("contentimgsidebyside" in card.metadata.attributes) {
          if (card.metadata.attributes.contentimgsidebyside[0] === "yes") {
            cardType[index] = "card1";
          }
        }
        if ("cardbackgroungcolour" in card.metadata.attributes) {
          if (card.metadata.attributes.cardbackgroungcolour.length >= 1) {
            console.log(
              "bgcloor",
              card.metadata.attributes.cardbackgroungcolour[0]
            );
            cardBgColor[index] =
              card.metadata.attributes.cardbackgroungcolour[0];
            setCardBgColor([...cardBgColor]);
          }
        }
      } else {
        card["story-elements"].map((element) => {
          if (
            element.type === "composite" &&
            element.subtype === "image-gallery"
          ) {
            tempcont = 1;
            cardType[index] = "card2";
          } else if (element.type === "image" && element.subtype === null) {
            cardType[index] = "card2";
          } else if (
            element.type === "text" &&
            element.subtype === "also-read"
          ) {
            cardType[index] = "also-read";
          } else {
            cardType[index] = "card2";
          }
        });
      }
    });
    console.log("cardtype", cardType);
    setcardtype(cardType);
    setDisplayFlag(true);
  }, []);
  console.log("a4 inside", data);

  const heroData = {
    img: imgBaseURL + data["story"]["hero-image-s3-key"],
    imgTitle: data["story"]["hero-image-caption"],
    cat: data["story"]["sections"][0]["name"].toUpperCase(),
    title: data["story"]["headline"],
    auth: data["story"]["author-name"],
    authId: data["story"]["author-id"],
  };
  if ("home" in data["story"]["alternative"]) {
    if ("default" in data["story"]["alternative"]["home"]) {
      console.log("alernative", data["story"]["alternative"]["home"]);
      if ("hero-image" in data["story"]["alternative"]["home"]["default"]) {
        if (
          data["story"]["alternative"]["home"]["default"]["hero-image"] != null
        ) {
          if (
            "hero-image-s3-key" in
            data["story"]["alternative"]["home"]["default"]["hero-image"]
          ) {
            console.log(
              data["story"]["alternative"]["home"]["default"]["hero-image"][
                "hero-image-s3-key"
              ]
            );
            heroData.img =
              imgBaseURL +
              data["story"]["alternative"]["home"]["default"]["hero-image"][
                "hero-image-s3-key"
              ];
          }
        }
      }
    }
  }
  const metaDataStory = {
    title: heroData.title,
    description: heroData.title,
    keywords: "The Established",
  };
  if ("seo" in data["story"]) {
    console.log("in seo");
    if ("meta-title" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-title"].length > 1) {
        metaDataStory.title = data["story"]["seo"]["meta-title"];
        console.log("in seo meta");
      } else {
        metaDataStory.title = heroData.title;
      }
    }
    if ("meta-description" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-description"].length > 1) {
        metaDataStory.description = data["story"]["seo"]["meta-description"];
      } else {
        metaDataStory.title = heroData.title;
        console.log("else in seo meta");
      }
    }
    if ("meta-keywords" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-keywords"].length > 1) {
        metaDataStory.keywords =
          data["story"]["seo"]["meta-keywords"].toString();
      }
    }
  } else {
    metaDataStory.title = heroData.title;
    metaDataStory.description = heroData.title;
  }

  const getSchemaData = useMemo(() => {
    return generateSchemaData(props?.data, metaDataStory);
  }, [props.data]);

  return (
    <StyleArticle>
      <Helmet defer={false}>
        <title>{metaDataStory.title}</title>
        <meta
          name="description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />

        <meta
          property="twitter:url"
          content={window.location.href}
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content={metaDataStory.title}
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content={metaDataStory.description}
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content={heroData.img}
          data-react-helmet="true"
        />
        <link
          rel="canonical"
          href={
            data["story"]["canonical-url"]
              ? data["story"]["canonical-url"]
              : window.location.href
          }
          data-react-helmet="true"
        />
        <meta name="author" content={heroData.auth} data-react-helmet="true" />
        <meta name="keywords" content={metaDataStory.keywords} />
      </Helmet>
      {/* Google Tag Manager (gtag.js) */}
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
        {props?.data && (
          <script type="application/ld+json">
            {JSON.stringify(getSchemaData)}
          </script>
        )}
      </Helmet>

      <div className="a4-header">
        <div className="a4-header-img" style={{ width: "50%" }}>
          <img src={heroData.img} alt="" />
        </div>
        <div className="a4-header-con">
          <div className="Biotif-Book a4-header-cat ">{heroData.cat}</div>
          <h1 className="HeldaneDisplay-Regular a4-header-title">
            {heroData.title}
          </h1>
          <Link
            to={{
              pathname: `/author/${heroData.authId}/${heroData.auth
                ?.replace(/ /g, "-")
                .toLowerCase()}`,
              state: { story: data, authId: heroData.authId },
            }}
          >
            <div className="Biotif-Book a4-header-auth">By {heroData.auth}</div>
          </Link>
          <p className="style-date Biotif-SemiBold">
            {moment(data["story"]["published-at"]).format("DD MMMM, YYYY")}
          </p>
          <div></div>
          <div className="d-flex eff1-social mtp-3 mbp-3">
            <FacebookShareButton
              className="eff1-social-images cursor-pointer h-full"
              url={window.location.href}
              quote={heroData.title}
            >
              <img
                className="eff1-social-images cursor-pointer"
                src={Facebook}
                alt="Facebook"
              ></img>
            </FacebookShareButton>
            <LinkedinShareButton
              className="cursor-pointer"
              title={heroData.title}
              url={window.location.href}
              description={metaDataStory.description}
              source={window.location.href}
            >
              <img
                className="eff1-social-images cursor-pointer"
                src={Instagram}
                alt="Instagram"
              ></img>
            </LinkedinShareButton>
            {/* <img className='social-images' src = {Tiktok} alt='Tiktok'></img> */}
            <WhatsappShareButton
              className="cursor-pointer"
              title={heroData.title}
              url={window.location.href}
            >
              <img
                className="eff1-social-images-whats-app cursor-pointer"
                src={WhatsApp}
                alt="WhatsApp"
              ></img>
            </WhatsappShareButton>
          </div>
        </div>
      </div>
      <div
        className="a4-header-img-title"
        dangerouslySetInnerHTML={{ __html: heroData.imgTitle }}
      ></div>
      {displayFlag &&
        cardtype.map((card, index) =>
          card === "card1" ? (
            <>
              <EstdInterviewCard1 cards={cards[index]} />
            </>
          ) : card === "card2" ? (
            <>
              <EstdInterviewCard2 cards={cards[index]} cardNo={index + 1} />
            </>
          ) : (
            ""
          )
        )}

      {displayFlag &&
        cardtype.map((card, index) =>
          card === "also-read" ? (
            <>
              <ReadMoreSection storyElement={cards[index]["story-elements"]} />
              {/* <div className="web-view-ad-img web-view-ad-img-horizontal-regular">
                        <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Horizontal} />
                    </div>
                    <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
                        <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square} />
                    </div> */}
            </>
          ) : (
            ""
          )
        )}
    </StyleArticle>
  );
};

export default EstdInterview;

{
  /* <div className='row ma-0 row-data-2'>
                <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>
                    <div className='description-head-2 pbp-5' dangerouslySetInnerHTML={{__html:mainData[0].title}}></div>
                    <div className='desc-answer HeldaneDisplay-Regular' dangerouslySetInnerHTML={{__html:mainData[0].para}}>
                    </div>

                </div>
                <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'>
                    <div>
                        <img width="100%" src={mainData[0].img.img} alt='fashion'></img>
                        <div className='HeldaneDisplay-Regular image-text-2'dangerouslySetInnerHTML={{__html:mainData[0].img.imgTitle}}></div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content'>
                <div className='description-question Biotif-SemiBold'dangerouslySetInnerHTML={{__html:mainData[1].title}}></div>
            </div>
            <div className='d-flex justify-content ptp-2'>
                <div className='answer-description HeldaneDisplay-Regular'dangerouslySetInnerHTML={{__html:mainData[1].para}}></div>
            </div>
            <div className='row new-images-grid'>
                {
                    mainData[1]['img'].map(image=>(
                        <div>
                            <img width="100%" height="100%" src={image.img} alt='asset-12'></img>
                            <p className='image-text-2 HeldaneDisplay-Regular' dangerouslySetInnerHTML={{__html:image.imgTitle}} ></p>
                        </div>
                    ))
                }
            </div>
            <div className='d-flex justify-content'>
                <div className='description-question Biotif-SemiBold' dangerouslySetInnerHTML={{__html:mainData[2].title}}></div>
            </div>
            <div className='d-flex justify-content ptp-2'>
                <div className='answer-description HeldaneDisplay-Regular' dangerouslySetInnerHTML={{__html:mainData[2].para}}>
                </div>
            </div>
            <div className='bold-caption' dangerouslySetInnerHTML={{__html:mainData[2].quote}}>
            </div>
            <div className='ptp-10'>
                <img width="100%" src={mainData[2].img.img} alt='asset-14'></img>
                <div className='image-text-3 plp-10 HeldaneDisplay-Regular'dangerouslySetInnerHTML={{__html:mainData[2].img.imgTitle}}></div>
            </div>
            <div className='d-flex justify-content'>
                <div className='description-question Biotif-SemiBold'dangerouslySetInnerHTML={{__html:mainData[3].title}}></div>
            </div>
            <div className='d-flex justify-content ptp-2'>
                <div className='answer-description HeldaneDisplay-Regular' dangerouslySetInnerHTML={{__html:mainData[3].para}}>
                </div>
            </div>
            <div className='row ma-0 ptp-5 plp-10 prp-10'>
                <div className='read-more-new1 InterstateCompressed-Bold'>READ MORE</div>
                <div className='read-more-grid'>
                    {itemsArr.map(result => (
                        <div>
                            <img width="100%" src={result.img}></img>
                            <div className='read-more-subc mtp-3 mbp-1'>{result.subc}</div>
                            <div className='Biotif-SemiBold read-more-desc mbp-2'>{result.title}</div>
                            <div className='read-more-subc Biotif-Book mbp-5'>{result.auth}</div>
                        </div>
                    ))}
                </div>
            </div> */
}
