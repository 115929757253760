import React from "react";
import InstagramIcon from "../../../assets/images/insta1.svg";
import FacebookIcon from "../../../assets/images/fb1.svg";
import LinkedinIcon from "../../../assets/images/linkedinlogo.svg";
import Twitter from "../../../assets/images/twitter1.svg";
import logo from "../../../assets/images/LOGO.svg";
import Youtube from "../../../assets/images/yt-icon-2.png";
import "./style.css";
import { withRouter } from "react-router-dom";

function HamFooter(props) {
  return (
    <div className="ham-footer-main">
      <div className="ham-footer-1">
        <div className="ham-footer-name">
          <img
            src={logo}
            alt="logo"
            className="cursor-pointer"
            onClick={() => {
              if (window.location.pathname === "/") {
                window.scroll(0, 0);
              } else {
                props.history.push("/");
              }
            }}
          />
        </div>
        <div className="ham-footer-logo">
          <img
            src={FacebookIcon}
            alt="FB icon"
            className="mr-12 icon-height wp-10 cursor-pointer"
            onClick={() =>
              window.open(
                "https://www.facebook.com/theestablishedcom",
                "_blank"
              )
            }
          ></img>
          <img
            src={InstagramIcon}
            alt="Insta icon"
            className="mr-12 icon-height wp-10 cursor-pointer "
            onClick={() =>
              window.open("https://instagram.com/theestablished_", "_blank")
            }
          ></img>
          <img
            onClick={() =>
              window.open(
                "https://in.linkedin.com/company/theestablished",
                "_blank"
              )
            }
            src={LinkedinIcon}
            alt="Linkedin icon"
            className=" mr-12 icon-height wp-10 cursor-pointer"
          ></img>
          <img
            onClick={() =>
              window.open("https://www.youtube.com/@TheEstablished11", "_blank")
            }
            src={Youtube}
            alt="Youtube icon"
            className="icon-height wp-10 cursor-pointer"
          ></img>
        </div>
      </div>
      <div className="ham-footer-2">
        <div className="ham-footer-2-1">
          <div className="ham-footer-2-1-1">
            <div
              className="Biotif-Regular"
              onClick={() => props.history.push("/about-us")}
            >
              ABOUT US
            </div>
            
            <div
              className="Biotif-Regular"
              onClick={() => props.history.push("/partner-with-us")}
            >
              PARTNER WITH US
            </div>
            <div
              className="Biotif-Regular"
              onClick={() => props.history.push("/privacy-policy")}
            >
              PRIVACY
            </div>
            <div
              className="Biotif-Regular"
              onClick={() => props.history.push("/news-letter")}
            >
              SUBSCRIBE
            </div>
          </div>
        </div>
        <div className="ham-footer-theest Biotif-Regular">
          © The Established 2021
        </div>
      </div>

      {/* <div className='ham-footer-2'>
        <div className='ham-footer-2-1'>
          <div className='Biotif-Regular'>
            NEWSLETTER
          </div>
          <div className='Biotif-Regular'>
            ABOUT US
          </div>
          <div className='Biotif-Regular'>
            HELP
          </div>
          <div className='Biotif-Regular'>
            CONTACT
          </div>
          <div className='Biotif-Regular'>
            WE'RE HIRING
          </div>
          <div className='Biotif-Regular'>
            PRESS
          </div>
          <div className='Biotif-Regular'>
            TERMS
          </div>
          <div className='Biotif-Regular'>
            AD CHOICES
          </div>
          <div className='Biotif-Regular'>
            COOKIES
          </div>
        </div>
        <div className='ham-footer-theest Biotif-Regular'>
        © The Established. 2021.
        </div>


    </div> */}
    </div>
  );
}
export default withRouter(HamFooter);
