import styled from 'styled-components';

const RandomStyle= styled.div`
.style-title{
    text-align:center;
    color: #ff003d;
    font-size:30px;
    font-family: "InterstateCompressed-Bold" !important;
}
.internal-div{
    text-align:center;
    font-size:30px;
    margin-left:30%;
    margin-right:30%;
    line-height:1.06;

}
.style-author{
    text-align:center;
    color: #ff003d;
    font-size:30px;
    font-family: "InterstateCompressed-Bold" !important;

}
p{
    font-family: "Heldane_Text_Regular" !important;
    margin-bottom:5%;
}
img{
    width:100%;
}
.main-hero-img{
    width:50% !important;
}
.image-bundle{
    display: grid;
    grid-template-columns: auto auto;
    gap: 2%;
    margin-bottom:5%;
}
h2{
    font-family: "InterstateCompressed-Bold" !important;
    margin-bottom: 3%;
    font-size: 80px;
    line-height: 0.9;
    letter-spacing: -10;
    text-align: center;
    color: #ff003d;
}

// Advertisement
.web-view-ad-img {
    text-align: center;
    margin: 3%;
}
.phone-view-square-img{
    display:none;
}
@media (max-width: 480px) {
    .web-view-ad-img{
        display:none;
    }
    .phone-view-square-img{
        display:block;
    }
}
// END Advertisement

`

export default RandomStyle;