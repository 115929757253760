import React from "react";
import { withRouter } from "react-router-dom";
import "./style.css";

function Hamself(props) {
  return (
    <div className="ham-2">
      <div className="ham-style-right">
        <div className="ham-center">
          <div
            className="ham-style-right-opt Biotif-Bold"
            onClick={() => props.history.push("/self/beauty", { key: "1" })}
          >
            → BEAUTY & GROOMING
          </div>
          <div
            className="ham-style-right-opt Biotif-Bold"
            onClick={() =>
              props.history.push("/self/health-wellness", { key: "2" })
            }
          >
            → HEALTH & WELLNESS
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Hamself);
