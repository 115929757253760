import React, { useState, useEffect } from "react";
import EstdFullFormat1 from "./EstdFullFormat1/EstdFullFormat1";
import EstdFullFormat2 from "./EstdFullFormat2/EstdFullFormat2";
import EstdFullFormat3 from "./EstdFullFormat3/EstdFullFormat3";
import CommonArticle from "./CommonArticle";
import EstdFullFormatDynamic from "./Articles-1/EstdFullFormatDynamic";
import EstdInterview from "./Articles-2/EstdInterview";
import EstdSlideShow from "./Article-5/EstdSlideShow";
import RandomLayout from "./RandomLayout";
import { useParams, withRouter } from "react-router-dom";
import Api from "../../helper/Api";
import Header from "../../components/common/Header2/Header";
import Footer from "../../components/common/Footer/Footer";
import SubscribeSticky from "../../components/common/SubscribeSticky/SubscribeSticky";

function Index(props) {
  console.log("in main article page", props.location.state);
  const [propsData, setPropsData] = useState(props.location.state);

  const [displayData, setDisplayData] = useState(false);
  const { id } = useParams();

  // console.log("Path name", props.location.pathname);
  const storyBySlug = props.location.pathname.slice(1);
  console.log("storyBySlug: ", storyBySlug);
  // const storyBySlug='fashion/2022/02/16/everything-youre-wearing-is-dictated-by-sporting-giant'
  useEffect(() => {
    window.scroll(0, 0);
    // console.log('inside useeffect',props.location.state);
    setDisplayData(false);

    // if(typeof(props.location.state)!='undefined'){
    //   console.log('inside useeffect if',props.location.state);
    //     setPropsData(props.location.state);
    //     setDisplayData(true);
    // }
    // else{
    //   Api('GET',`/api/v1/stories-by-slug?slug=${storyBySlug}`,{}).then(response=>{
    //     // console.log(storyBySlug,'storyslug')
    //     // console.log('use effect api call for story by slug',response);
    //     setPropsData(response.data);
    //     setDisplayData(true);
    //   }
    //   )
    // }
    {
      Api("GET", `/api/v1/stories-by-slug?slug=${storyBySlug}`, {}).then(
        (response) => {
          // console.log(storyBySlug,'storyslug')
          // console.log('use effect api call for story by slug',response);
          setPropsData(response.data);
          setDisplayData(true);
        }
      );
    }
  }, [props]);

  console.log("propsdata", propsData);
  return (
    <div>
      <Header />
      {displayData === true ? (
        propsData["story"]["story-template"] === "estd-full-format-1" ? (
          <EstdFullFormat1 data={propsData} />
        ) : propsData["story"]["story-template"] === "estd-full-format-2" ? (
          <EstdFullFormat2 data={propsData} />
        ) : propsData["story"]["story-template"] === "estd-full-format-3" ? (
          <EstdFullFormat3 data={propsData} />
        ) : propsData["story"]["story-template"] ===
          "estd-full-format-dynamic" ? (
          <EstdFullFormatDynamic data={propsData} />
        ) : propsData["story"]["story-template"] === "estd-interview" ? (
          <EstdInterview data={propsData} />
        ) : propsData["story"]["story-template"] === "estd-slideshow" ? (
          <EstdSlideShow data={propsData} />
        ) : propsData["story"]["story-template"] === "text" ? (
          <RandomLayout data={propsData} />
        ) : (
          <div style={{ height: "80vh" }}></div>
        )
      ) : (
        <div style={{ height: "80vh" }}></div>
      )}
      <SubscribeSticky />
      <Footer />
    </div>
  );
}

export default withRouter(Index);
// {
//   templetType==='story-template-4'?<EstdFullFormat1 data = {propsData}/>: templetType==='estd-full-format-2'? <Article5 data = {propsData}/>:templetType==='text'?<CommonArticle data={propsData}/>:""
// }
