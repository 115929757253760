import React from 'react';


const EstdFullFormatDynamicCard1 = (props) => {
    const { cards } = props
    const storyElement = cards['story-elements']
    console.log('story ele', storyElement)
    if (storyElement) {
        return <div className='ptp-5 pbp-5'>
            <div className='row ma-0 row-data'>
                {
                    storyElement.map((element) => (
                        element.type === 'text' ? <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12 p-0'>
                            <div className='description-head' dangerouslySetInnerHTML={{ __html: element.text }}>
                            </div></div> : element.type === 'image' ? <div className='col-lg-6 col-xl-6 col-md-12 col-sm-12 p-0'><div className='ffd-b-img'>
                                <img width="100%" src={`https://gumlet.assettype.com/${element['image-s3-key']}`} alt='fashion'></img>
                                <div className='HeldaneDisplay-Regular image-text' dangerouslySetInnerHTML={{ __html: element['title'] }}></div>
                            </div></div> : ""
                    ))
                }

                {/* {
                    storyElement.map((element)=>(
                        element.type==='image'?<div className='col-lg-6 col-xl-6 col-md-12 col-sm-12'><div>
                            <img width="100%" src = {`https://gumlet.assettype.com/${element['image-s3-key']}`} alt='fashion'></img>
                            <div className='HeldaneDisplay-Regular image-text' dangerouslySetInnerHTML={{ __html: element['title']}}></div>
                        </div></div>:""
                        ))
                    } */}

            </div>
        </div>;
    }
    else return <div></div>
};

export default EstdFullFormatDynamicCard1;
