import React from "react";
import LeftMenu from "./LeftMenu";
import { Drawer } from "antd";
import { useState ,useEffect} from "react";
import Search from "../../../assets/images/search.svg";
import NavDrawer from "../../../assets/images/bar11.png";
import Search1 from "../../../assets/images/Search-test.png";
import Close from "../../../assets/images/close-search.png";
import HamShow from "./HamShow";
import HamFooter from "./HamFooter";
import logo2 from '../../../assets/images/LOGO2.svg'
import { withRouter } from "react-router-dom";
import './style.css';
import StyleWrapper from './style';
import ModernDrawer from 'react-modern-drawer';
import Api from '../../../helper/Api';
import axios from "axios";
import logo from '../../../assets/images/LOGO.svg'


//import styles 👇
import 'react-modern-drawer/dist/index.css'


const Navbar = (props) => {
  console.log('props', props);
  const [visible, setVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [category, setCategory] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [trendResponse, setTrendResponse] = useState([]);
  useEffect(() => {
    Api('GET', '/api/v1/collections/breaking-news', {}, {}).then((r) => {
      console.log('header breaking news', r);
      setTrendResponse(r.data.items);
  })
  }, [])
  useEffect(() => {
    Api('GET', '/api/v1/search', {}, { 'q': inputValue}).then((r) => {
      console.log('search query', r.data.results.stories);
      setSearchResponse(r.data.results.stories);
  })
  }, [inputValue])

  const onChange = e => {
    console.log(e);
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const showDrawerSearch = () => {
    setSearchVisible(true);
  }
  const onClose = () => {
    setVisible(false);
    console.log('clicked');
    setCategory('');
  };
  const displayCategory = (data) => {
    setCategory(data);
  }
  const displayHideCategory = () => {
    setTimeout(() => {
      setCategory('');
    }, 10000);

  }
  const onCloseSearch = () => {
    setSearchVisible(false);
  }
  const handleIndividualStory=(a)=>{
    props.history.push(`/${a['slug']}`);
}
const handleTrendingStory=(a)=>{
  console.log('onclick',a);
      props.history.push(`/${a['story']['slug']}`, a);
}
// onClick={()=>props.history.push('/search',searchResponse)}
function handleEnter(e){
  console.log('enter')
  if(e.key === 'Enter'){
      e.preventDefault(); // Ensure it is only this code that runs
        props.history.push('/search',{searchResponse:searchResponse,trendResponse:trendResponse,inputValue:inputValue})
      }
}
  //className="menuBar"
  return (
    <StyleWrapper>
    <div className="d-flex justify-content-between nav-1-main-div">
      <div className="search-div">
        <img onClick={showDrawerSearch}  className="cursor-pointer" src={Search} alt="search-icon"></img>
        <div className="search-bar-drawer">
          <ModernDrawer className="modern-drawer" style={{background:'black'}}
            direction="top"
            //closable={true}
            onClose={onCloseSearch}
            open={searchVisible}
          >
            <div className="input-field-view plp-5 d-flex">
              <div className="inside-search-tag"><img src={Search1} alt="search-1"></img></div>
              <div className="form__group-1 d-flex">
                <input style={{borderBottom:'1px solid white'}} autocomplete="off" onKeyPress={(e)=>handleEnter(e)} value={inputValue} onChange={e => setInputValue(e.target.value)} type="input" class="form__field-1 plp-1 Biotif-Regular" placeholder="Search" name="search" id='search' />
                {/* <div className="arrow-input" style={{color:"white"}}>Clear</div> */}
              </div>
            </div>
            <div className="plp-2 ptp-2 fs-30">
              {inputValue!=='' ? searchResponse.slice(0,5).map(item => (
                <div onClick={()=>{handleIndividualStory(item)}}  className="text-white search-result-div plp-5 prp-5 cursor-pointer Biotif-SemiBold pbp-1">{item.headline}</div>
              )) : trendResponse.slice(0,5).map(item => (
                <div onClick={()=>{handleTrendingStory(item)}}  className="text-white search-result-div plp-5 cursor-pointer Biotif-SemiBold pbp-1">{item.story.headline}</div>
              ))}
            </div>
          </ModernDrawer>
        </div>
      </div>
      <div className={props.scrollData === true ? `d-flex justify-content-center` : 'menuCon'}>
        {
          props.scrollData === true ? <div className="" style={{ width: "40%" }} >
            <img id="new-image" className="cursor-pointer" onClick={()=>window.scroll(0,0)} style={{ width: "100%" }} src={logo2} alt="the-established"></img>
          </div> :
          <div style={{backgroundColor: '#ff003d'}}>
<div className="d-flex justify-content pbp-2">
            <img id="new-image" className="theestdfulllogo" src = {logo} alt="the-established"></img>
          </div>
          <div
            className={`leftMenu`}
            // className="leftMenu"
            style={{ width: "100%" }}>
            <LeftMenu />
          </div>
          </div>
        }
        {/* <div className="rightMenu">
            <RightMenu />
          </div> */}
      </div>
      <div className="drawer-icon-view-div" >
        {/* <Button className="barsMenu" type="primary" onClick={showDrawer}>
            <span className="barsBtn" />
          </Button> */}
        <img onClick={showDrawer}  className="drawer-icon-view cursor-pointer" src={NavDrawer} alt="drawer-icon"></img>
        <div className="right-side-drawer">
          <Drawer
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
          >

            <HamShow displayCategory={displayCategory} category={category} displayHideCategory={displayHideCategory} onClose={onClose} />
            <HamFooter />

          </Drawer>
        </div>
      </div>
    </div>
    </StyleWrapper>
  );
}
export default withRouter(Navbar);
