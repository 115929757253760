import React, { useEffect } from "react";
import newsletterimg from "../../assets/images/newsletter-1.jpg";
import Header from "../../components/common/Header2/Header";
import Footer from "../../components/common/Footer/Footer";
import Mailchimp from "../Mailchimp/Mailchimp";
import { Helmet } from "react-helmet";
import "./style.css";
const NewsLetter = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Helmet defer={false}>
        <title>
          Newsletter Subscription for The Established | Subscribe for Latest
          News
        </title>
        <meta
          name="description"
          content="Get a fresh perspective on fashion, beauty and culture straight in your inbox. Become a part of our community of conscious, authentic and well-informed, progressive readers. "
        />
      </Helmet>
      {/* Google Tag Manager (gtag.js) */}
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16472986144');
    `}
        </script>
        {/* Event snippet for Subscribe Button conversion page */}
        <script>
          {`
      gtag('event', 'conversion', {'send_to': 'AW-16472986144/Qm6fCI-agJYZEKCk9649'});
    `}
        </script>
      </Helmet>

      <Header />
      <div className="newsletter-main-div">
        {/* <div className='newsletter-main-img'>
                    <img src={newsletterimg} alt="" />
                </div> */}
        <div className="news-letter-main">
          <Mailchimp from="NEWSLETTER" {...props} />
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default NewsLetter;
