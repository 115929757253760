import React from "react";
import { imgBaseURL, verticalAdContentLength } from "../../../helper/constants";
import GoogleAdvertisement from "../../Advertise/GoogleAdvertisement";

const EstdInterviewCard2 = (props) => {
  const { cards, cardNo, cardBgColor } = props;
  console.log(props);
  const storyElement = cards["story-elements"];
  console.log("story ele", storyElement);
  if (storyElement) {
    return (
      <div className="mbp-5">
        {storyElement.map((element, index) =>
          element.type === "text" ? (
            <>
              {/* {(index == 0 && element.text && element.text.length >= verticalAdContentLength) &&
                    <>
                        <div className={`web-view-ad-img ${cardNo % 2 == 0 ? "web-view-ad-img-vertical-left" : "web-view-ad-img-vertical-right"}`}>
                            <GoogleAdvertisement dataAdSlot={process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Vertical} />
                        </div>
                    </>
                    } */}
              <div
                className="interview-question-answer"
                dangerouslySetInnerHTML={{ __html: element.text }}
              ></div>
              {/* {index == 0 && (
                <>
                  <div className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5">
                    <GoogleAdvertisement
                      dataAdSlot={
                        process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square
                      }
                    />
                  </div>
                </>
              )} */}
            </>
          ) : element.type === "image" ? (
            <div>
              <div className="ptp-2">
                <img
                  width="100%"
                  src={`${imgBaseURL}${element["image-s3-key"]}`}
                  alt=""
                />
              </div>
              <div
                className="a5-three-img-cap Heldane-Text-Regular"
                dangerouslySetInnerHTML={{ __html: element["title"] }}
              ></div>
            </div>
          ) : element.subtype === "image-gallery" ? (
            <div className="new-images-grid">
              {element["story-elements"].map((imageData) => (
                <div>
                  <img
                    width="100%"
                    src={`${imgBaseURL}${imageData["image-s3-key"]}`}
                  ></img>
                  <div
                    className="image-text-2"
                    dangerouslySetInnerHTML={{ __html: imageData["title"] }}
                  ></div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )
        )}
      </div>
    );
  } else return <div></div>;
};

export default EstdInterviewCard2;
