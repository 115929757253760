import React from 'react';
import SelfWrapper from './style';
import self from '../../assets/images/title_svg_self.svg'
import { imgBaseURL } from '../../helper/constants';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { subCatPageUrl } from '../../helper/constants'


// const itemsArr = [
//     {id:1,image:Slide,question:'Are you ready to switch to e-cars?',author:'By Farhad J. Dadyburjor'},
//     {id:2,image:Bottle,question:'Indie beauty brands are giving the OGs a run for their money',author:'By Shivangi Lolayekar'},
//     {id:3,image:Basketball,question:'Why Indian musicians are betting on NFTs',author:'By Shruti Thacker'},
//     {id:4,image:Car,question:'Are you ready to switch to e-cars?',author:'By Farhad J. Dadyburjor'},
// ]

const Self = (props) => {
    const resp = props.resp
    const handleIndividualStory = (a) => {
        console.log('onclick', a['story']['sections'][0]);
        props.history.push(`/${a['story']['slug']}`, a);
    }
    if (resp.length) {
        return (
            <SelfWrapper>
                <div className='black-bg'>
                    <div className='self-title InterstateCompressed-Bold'>
                        <img src={self} alt="self logo" className="cursor-pointer" onClick={() => { props.history.push('/self') }} />
                    </div>
                    <div className='image-position black-bg'>
                        <div>
                            <div className='flex-1'>
                                {resp.slice(0, 2).map((a, index) => (
                                    <div className={`flex-1-${index} flex-1-all`} style={{ cursor: 'pointer' }}>
                                        <Link to={{
                                            pathname: `/${a['story']['slug']}`,
                                            state: { story: a }
                                        }}>
                                            <img style={{ objectFit: 'cover' }} width="100%" src={`${imgBaseURL}${a['story']['hero-image-s3-key']}`}></img>
                                            <Link to={{
                                                pathname: subCatPageUrl[a['story']['sections'][0]['slug']].url,
                                                state: { key: subCatPageUrl[a['story']['sections'][0]['slug']].key }
                                            }}>

                                                <div className='sub-category Biotif-Book'>
                                                    {a['story']['sections'][0]['name'].toUpperCase()}
                                                </div>
                                            </Link>
                                            <div className='question Biotif-SemiBold'>
                                                {a['story']['headline']}
                                            </div>
                                            <div className='author Biotif-Book'>
                                                By {a['story']['author-name']}
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            <div className='flex-2'>
                                {resp.slice(2, 4).map((a, index) => (
                                    <div className={`flex-2-${index} flex-2-all`} style={{ cursor: 'pointer' }}>
                                        <Link to={{
                                            pathname: `/${a['story']['slug']}`,
                                            state: { story: a }
                                        }}>
                                            <img style={{ objectFit: 'cover' }} width="100%" src={`${imgBaseURL}${a['story']['hero-image-s3-key']}`}></img>
                                            <Link to={{
                                                pathname: subCatPageUrl[a['story']['sections'][0]['slug']].url,
                                                state: { key: subCatPageUrl[a['story']['sections'][0]['slug']].key }
                                            }}>
                                                <div className='sub-category Biotif-Book'>
                                                    {a['story']['sections'][0]['name'].toUpperCase()}
                                                </div>
                                            </Link>
                                            <div className='question Biotif-SemiBold'>
                                                {a['story']['headline']}
                                            </div>
                                            <div className='author Biotif-Book'>
                                                By {a['story']['author-name']}
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                    <div className='d-flex justify-content Biotif-Regular mtp-5 pbp-8'>
                        <button className='read-more-btn ' type='submit' onClick={() => props.history.push('/self')}>READ MORE →</button>
                    </div>
                </div>
                <div style={{ position: "relative" }} className='cursor-pointer'>
                    <Link to={{
                        pathname: `/${resp[4]['story']['slug']}`,
                        state: { story: resp[4] }
                    }}>
                        <img width="100%" style={{ maxHeight: '95vh', objectFit: 'cover' }} src={`${imgBaseURL}${resp[4]['story']['hero-image-s3-key']}`} alt='group-photo-image' />
                        <div className='image-data'>
                            <div className='image-title-2 Biotif-Regular'>
                            <Link to={{
                  pathname: subCatPageUrl[resp[4]['story']['sections'][0]['slug']].url,
                  state: { key: subCatPageUrl[resp[4]['story']['sections'][0]['slug']].key }
                }}>
                                <span className='image-title-2 Biotif-Regular'>
                                {resp[4]['story']['sections'][0]['name'].toUpperCase()}
                                </span>
                </Link>
                                 – By <em> {resp[4]['story']['author-name']}</em>
                            </div>
                            <div className='image-text-2 Biotif-SemiBold cursor-pointer' onClick={() => { handleIndividualStory(resp[4]) }}>
                                {resp[4]['story']['headline']}
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='pink-border mtp-5'></div>
                <Link to={{
                        pathname: `/${resp[5]['story']['slug']}`,
                        state: { story: resp[5] }
                    }}>
                <div className='text-section-1 text-section-2 Biotif-Medium'>
                <Link to={{
                  pathname: subCatPageUrl[resp[4]['story']['sections'][0]['slug']].url,
                  state: { key: subCatPageUrl[resp[4]['story']['sections'][0]['slug']].key }
                }}>
                    <span className='text-section-1 text-section-2 Biotif-Medium'>
                    {resp[5]['story']['sections'][0]['name'].toUpperCase()}
                    </span>
                </Link>

                     – By <em>{resp[5]['story']['author-name']}</em>
                </div>
                <div className='description-section-2 Heldane_Display_Regular_Italic mtp-1 cursor-pointer'>
                    {resp[5]['story']['subheadline']}
                </div>
                </Link>
                <div className='pink-border mtp-5'></div>
            </SelfWrapper>

        )
    }
    else {
        return <div></div>
    }
}
export default (withRouter)(Self);
