import React from 'react';
import { imgBaseURL } from '../../../helper/constants';

const EstdFullFormat2card1 = (props) => {
    const { cards } = props;
    console.log('cards', cards, props)
    const storyElement = cards['story-elements'];
    if (storyElement) {
        return <div className='a5-p1'>
            {
                storyElement.map((element) => (
                    element.type === 'text' ?

                    <div className='a5-p1-p1' dangerouslySetInnerHTML={{ __html: element.text}}>

                </div>:
                        element.type === 'image' ? <div><div className='a5-three-img'>
                        <div className='a5-three-img-1'>
                            <img src={`https://gumlet.assettype.com/${element['image-s3-key']}`} alt="" />
                        </div>
                        <div className='a5-three-img-1'>
                            <img src={`https://gumlet.assettype.com/${element['image-s3-key']}`} alt="" />
                        </div>
                        <div className='a5-three-img-1'>
                            <img src={`https://gumlet.assettype.com/${element['image-s3-key']}`} alt="" />
                        </div>
                    </div>
                    <div className='a5-three-img-cap Heldane-Text-Regular' dangerouslySetInnerHTML={{ __html: element['title']}}></div>
                    </div> : element.subtype === 'image-gallery' ? <div className='a5-three-img'>
                        {
                            element['story-elements'].map(imageData => (
                                <div className='a5-three-img-1'>
                                    <img src={`${imgBaseURL}${imageData['image-s3-key']}`}></img>
                                    <div className='a5-three-img-cap' dangerouslySetInnerHTML={{ __html: imageData['title'] }}></div>
                                </div>
                            ))
                        }
                    </div>: ''
                ))

            }
        </div>;
    }
    else {
        return <div></div>
    }
};

export default EstdFullFormat2card1;
