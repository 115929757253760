import React from "react";
import { withRouter } from "react-router-dom";
import "./style.css";

function Advertisement(props) {
  const { img, url, linkStatus, fullWidth = false } = props;
  return (
    <div className="advertise-main mtp-2 mbp-2">
      <div
        className={`advertise-poster cursor-pointer ${
          fullWidth ? "" : "advertise-poster-margin"
        }`}
        onClick={() => {
          if (linkStatus === "internal") {
            props.history.push(url);
          } else if (linkStatus === "external") {
            window.open(url, "_blank");
          }
        }}
      >
        <img src={img} alt="" />
      </div>
    </div>
  );
}
export default withRouter(Advertisement);
