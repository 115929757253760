import React from "react";
import Marquee from "react-fast-marquee";
import { withRouter } from "react-router-dom";
import "./style.css";

function TickerComp(props) {
  const handleIndividualStory = (a) => {
    console.log('onclick', a['story']['sections'][0]);
    props.history.push(`/${a['story']['slug']}`, a);
}
  return (
    <div
      style={{
        backgroundColor: "#FA062E",
        color: "white",
        display: "flex",
        width: "100%",
        alignItems: "center",
      }} className="ticker-main-main"
    >
    <Marquee gradient={false} className="marque" pauseOnHover={true} speed={25}>
    {
      props.resp.map((a)=>{
        return <div className="ticker-main InterstateCompressed-Bold">
          <div className="marque-heading InterstateCompressed-Bold">
            TRENDING
          </div>
          <div className="ticker-content cursor-pointer Heldane-Text-Regular" onClick={() => { handleIndividualStory(a) }}>{a.story.headline}</div>
          <div className="read-more">
            <div className="Biotif-Regular cursor-pointer" onClick={() => { handleIndividualStory(a) }}>READ MORE → </div>
          </div>

    </div>
      })
    }
    </Marquee>
    </div>
  );
}
export default withRouter (TickerComp)








// <Ticker speed={2} height={100}>
    //   {({ index }) => (
    //       <div style={{backgroundColor:'#FA062E', color:'white' ,alignItems:'center',height:'50px'}} className='d-flex flex-row'>
    //       <h1 style={{paddingRight:'20px', top:'-2px',height:'50px'}}>
    //         Trending
    //       </h1>
    //       <div className='ticker-content d-flex flex-row'>
    //         {arr[0]}
    //       </div>
    //       <div className='read-more' style={{marginLeft:'20px',marginRight:'100px'}}>
    //         <a href="/">read more</a>
    //       </div>
    //       </div>

    //   )}
    // </Ticker>
    // <marquee>
    //   {arr[0]}
    // </marquee>
