import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"
import "./style.css";

export default function SubscribeSticky() {
  const [isFixed, setIsFixed] = useState(true);
  const history = useHistory();
  
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
    
      const handleScroll = () => {
        const footer = document.querySelector(".footer-main-div-0");
        if (footer) {
          const rect = footer.getBoundingClientRect();
          const isInViewport = rect.top < window.innerHeight;
          setIsFixed(!isInViewport);
        }
      };
  return (
    <div className="">
    <button
      className={`subscribe-btn ${isFixed ? "" : "fixed"}`}
      type="submit"
      onClick={() =>history.push("/news-letter")}
    >
      SUBSCRIBE →
    </button>
  </div>
  );
}
