import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { store, history, persistor } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Home from "../views/Home";
import Style from "../views/Style/MainCategories";
import Self from "../views/Self/Self";
import Community from "../views/Community/Community";
import Culture from "../views/Culture/Culture";
import article from "../views/ArticleLayout/index";
import Search from "../views/Search/Search";
import AboutUs from "../views/AboutUs/AboutUs";
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy";
import PartnerWithUs from "../views/PartnerWithUs/PartnerWithUs";
import NewsLetter from "../views/NewsLetter/NewsLetter";
import PreviewHome from "../views/PreviewHome/PreviewHome";
import author from "../views/Author/Author";
import SubscribeSuccess from "../views/SubscribeSuccess/SubscribeSuccess";
// import TagManager from 'react-gtm-module';
// import { gaTag } from "../helper/constants";

const Routes = () => {
  // useEffect(() => {
  //   TagManager.initialize(gaTag);
  // }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Switch>
            <Route path="/news-letter" component={NewsLetter} exact />
            <Route path="/subscribed" component={SubscribeSuccess} exact />
            <Route path="/partner-with-us" component={PartnerWithUs} exact />
            <Route
              path="/privacy-policy"
              component={PrivacyPolicy}
              exact
            ></Route>
            <Route path="/about-us" component={AboutUs} exact></Route>
            <Route path="/search" component={Search} exact></Route>

            <Route path="/culture" component={Culture} exact />
            <Route path="/culture/gaming-tech" component={Culture} exact />
            <Route path="/culture/entertainment" component={Culture} exact />
            <Route path="/culture/auto" component={Culture} exact />
            <Route path="/culture/living" component={Culture} exact />
            <Route path="/community" component={Community} exact />
            <Route path="/community/business" component={Community} exact />
            <Route
              path="/community/conscious-living"
              component={Community}
              exact
            />
            <Route path="/community/identity" component={Community} exact />
            <Route path="/community/viewpoint" component={Community} exact />
            <Route path="/self" component={Self} exact />
            <Route path="/self/health-wellness" component={Self} exact />
            <Route path="/self/beauty" component={Self} exact />
            <Route path="/style/fashion" component={Style} exact />
            <Route path="/style/sneakers" component={Style} exact />
            <Route path="/style/watches" component={Style} exact />
            <Route path="/style/:slug" component={article} exact />

            <Route path="/style/fashion/:slug" component={article} exact />
            <Route path="/style/watches/:slug" component={article} exact />
            <Route path="/style/sneakers/:slug" component={article} exact />
            <Route path="/style/design/:slug" component={article} exact />
            <Route path="/style/lifestyle/:slug" component={article} exact />

            <Route path="/self/:slug" component={article} exact />
            <Route path="/self/beauty/:slug" component={article} exact />
            <Route path="/self/health/:slug" component={article} exact />

            <Route path="/community/:slug" component={article} exact />
            <Route
              path="/community/conscious-living/:slug"
              component={article}
              exact
            />
            <Route path="/community/identity/:slug" component={article} exact />
            <Route path="/community/business/:slug" component={article} exact />
            <Route
              path="/community/viewpoint/:slug"
              component={article}
              exact
            />

            <Route path="/culture/:slug" component={article} exact />
            <Route path="/culture/living/:slug" component={article} exact />
            <Route path="/culture/auto/:slug" component={article} exact />
            <Route path="/culture/gaming/:slug" component={article} exact />
            <Route
              path="/culture/entertainment/:slug"
              component={article}
              exact
            />
            <Route path="/author/:id/:author" component={author} exact />

            <Route path="/style" component={Style} exact />
            <Route path="/preview/story" exact component={PreviewHome} />
            <Route path="/" exact component={Home} />
            <Route path="/" render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
};
export default Routes;
