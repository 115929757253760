import React from 'react'
import { withRouter } from 'react-router-dom'

const AdPillar = (props) => {
    const {img,url,linkStatus}=props
  return (
    <div>
 <div className="culture-pillar-main cursor-pointer" onClick={()=>{
                if(linkStatus==='internal'){
                    props.history.push(url)
                }
                else if(linkStatus==='external'){
                    window.open(url, "_blank")
                }
                }}>
                  <div className="culture-pillar-img">
                    <img src={img} alt="" />
                  </div>
                </div>
    </div>
  )
}

export default withRouter (AdPillar)