import axios from "axios";
import { baseUrl } from "./constants";
import { store } from "../redux/store";

const Api = (method, urlData,data={}, params={}) => {
  const url = `${urlData}`;
  const headers = {
    // 'Access-Control-Allow-Origin':'*',
    // 'Access-Control-Allow-Methods':'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    // 'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token',
    // 'Content-Type':'application/javascript'
  };

  if (store.getState().auth.accessToken) {
    headers["Authorization"] = store.getState().auth.accessToken;
  }
  let axiosObj = {
    method: method,
    url: url,
    headers: headers,
    data: data,
  };
  console.log('axios',axiosObj)
  switch (method) {
    case 'GET':
      return axios.get(url,{params:params});
    case 'POST':
      console.log('inpost')
      return axios.post(url,data).then(res=>console.log('bulk res',res)).catch(e=>console.log(e))
    default:
      break;
  }

};

export default Api;




// 1) GET - https://theestablished.qtstage.io/api/v1/stories/
// The story-group parameter is mandatory.
// Setting the story-group to 'top' will get the stories as sorted in the top stories sorter, then sorted as per published-at timestamp.
// The story-group param can be set to 'stack-{{stack-id}}' to fetch the stories for a stack, and will not default to more stories once the stack is consumed.

// 2) GET - https://theestablished.qtstage.io/api/v1/authors
// Fetches a list of all authors if no query params are supplied. Maximum of 20 authors will be fetched.

// 3) GET - https://theestablished.qtstage.io/api/v1/authors
//



// 4) GET - https://theestablished.qtstage.io/api/v1/trending/tags
// This api gives trending tags provided the limit and time period, by default it gives 5 tags for time period of 4 hours



// 5) GET - https://theestablished.qtstage.io/api/v1/breaking-news
// List all breaking-news



// 6) GET - https://theestablished.qtstage.io/api/v1/collections/{slug}
// A collection is an ordered grouping of stories and other collections. It can be of two types: manual or automated. A manual collection needs the user to pick the items, while an automated collection can return items based on a set of configurable rules. Both types allow manual items to be inserted at the top, where items can be stories or collections. If the sort type of an automated collection is trending, then the total-count is always returned as 0.