import React, { useEffect, useState } from "react";
import {
  imgBaseURL,
  maxContentAd,
  verticalAdContentLength,
} from "../../../helper/constants";
import GoogleAdvertisement from "../../Advertise/GoogleAdvertisement";
import { useMedia } from "../../../hooks/useMedia";

const test = ["card1_0", "card2_1_2", "card2_3_0"];

const EstdFullFormat2card2 = (props) => {
  const { cards, isLastCardType, index1, setLocAdCounter, locAdCounter } =
    props;

  const { isMobile } = useMedia();

  const storyElement = cards["story-elements"];

  useEffect(() => {
    const observerOptions = {
      threshold: 0.2, // Adjust this threshold as needed
    };

    const visibleThemes = document.querySelectorAll(
      isMobile ? ".helloAdd" : ".helloAddWeb"
    );

    const ids = [...locAdCounter]; //JSON.parse(sessionStorage.getItem("ADS_IDS")) || [];

    visibleThemes?.forEach((item, index) => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          if (!ids.includes(entry.target.id) && ids.length < 3) {
            ids.push(entry.target.id);
            sessionStorage.setItem("ADS_IDS", JSON.stringify(ids));
          }
          setLocAdCounter(JSON.parse(sessionStorage.getItem("ADS_IDS")) || []);
        }
      }, observerOptions);

      if (item) {
        observer.observe(item);
      }
      window.addEventListener("beforeunload", () => {
        sessionStorage.removeItem("ADS_IDS");
      });

      // Clean up the observer when the component unmounts
      return () => {
        window.removeEventListener("beforeunload", () =>
          sessionStorage.removeItem("ADS_IDS")
        );
      };
    });
  }, []);

  const getLeftOrRight = (ele) => {
    let val = 0;
    const test = [...locAdCounter];
    if (test?.length) {
      const index = test.findIndex((el) => el === ele);
      return index !== -1 ? index : 0;
    }
    return val;
  };

  if (storyElement) {
    return (
      <div className="a5-p2">
        {storyElement.map((element, index) =>
          element.type === "text" ? (
            <>
              {/* {(!(storyElement.length == index + 1) ||
                (storyElement.length == index + 1 && !isLastCardType)) &&
                (!locAdCounter?.length ||
                  (locAdCounter?.length < 3 &&
                    !locAdCounter.includes(`card2_${index1}_${index}`)) ||
                  locAdCounter.includes(`card2_${index1}_${index}`)) &&
                element.subtype != "blockquote" &&
                element.text &&
                element.text.length >= verticalAdContentLength &&
                ((index1 !== 1 && index !== 0) || index1 > 1) && (
                  <>
                    <div
                      id={`card2_${index1}_${index}`}
                      className={
                        getLeftOrRight(`card2_${index1}_${index}`) % 2 === 0
                          ? `web-view-ad-img web-view-ad-img-vertical-right helloAddWeb`
                          : `web-view-ad-img web-view-ad-img-vertical-left helloAddWeb`
                      }
                    >
                      <GoogleAdvertisement
                        id={`card2_${index1}_${index}`}
                        dataAdSlot={
                          process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Vertical
                        }
                      />
                    </div>
                  </>
                )} */}
              <div
                className="a5-p2-p2"
                dangerouslySetInnerHTML={{ __html: element.text }}
              ></div>
              {/* {(!(storyElement.length == index + 1) ||
                (storyElement.length == index + 1 && !isLastCardType)) &&
                (!locAdCounter?.length ||
                  (locAdCounter?.length < 3 &&
                    !locAdCounter.includes(`card2_${index1}_${index}`)) ||
                  locAdCounter.includes(`card2m_${index1}_${index}`)) &&
                element.subtype != "blockquote" && (
                  <>
                    <div
                      id={`card2m_${index1}_${index}`}
                      className="phone-view-square-img mlp-5 mrp-5 mbp-5 mtp-5 helloAdd"
                    >
                      <GoogleAdvertisement
                        dataAdSlot={
                          process.env.REACT_APP_GOOGLE_AD_DataAdSlot_Square
                        }
                      />
                    </div>
                  </>
                )} */}
            </>
          ) : element.type === "image" ? (
            <div>
              <div className="a4-img mbp-3">
                <div className="a4-img-1">
                  <img src={`${imgBaseURL}${element["image-s3-key"]}`} alt="" />
                  <div
                    className="a4-img-caption"
                    dangerouslySetInnerHTML={{ __html: element["title"] }}
                  ></div>
                </div>
                <div className="a4-img-1">
                  <img src={`${imgBaseURL}${element["image-s3-key"]}`} alt="" />
                  <div
                    className="a4-img-caption"
                    dangerouslySetInnerHTML={{ __html: element["title"] }}
                  ></div>
                </div>
              </div>
            </div>
          ) : element.subtype === "image-gallery" ? (
            <div className="a4-img">
              {element["story-elements"].map((imageData) => (
                <div className="a4-img-1">
                  <img
                    className="img-h-90"
                    src={`${imgBaseURL}${imageData["image-s3-key"]}`}
                  ></img>
                  <div
                    className="a4-img-caption"
                    dangerouslySetInnerHTML={{ __html: imageData["title"] }}
                  ></div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default EstdFullFormat2card2;
