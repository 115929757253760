import React from 'react'
import './style.css'
import Hamstyle from './Hamstyle';
import Hamself from './Hamself';
import Hamculture from './Hamculture';
import Hamcommunity from './Hamcommunity';
import { withRouter } from 'react-router-dom';

function HamShow(props) {
    const { displayCategory, category, displayHideCategory, onClose } = props

    return (
        <div>
            <div className='ham-main'>
                <div className='ham-1'>
                    <div className='text-shadow InterstateCompressed-Bold' style={{width:'6ch'}} onClick={() => {
                        props.history.push('/style');
                        onClose();
                    }} onMouseEnter={() => { displayCategory('style') }} onMouseLeave={displayHideCategory}>
                        STYLE
                    </div>
                    <div className='text-shadow InterstateCompressed-Bold' style={{width:'5ch'}} onClick={() => {
                        props.history.push('/self')
                        onClose();
                    }} onMouseOver={() => { displayCategory('self') }} onMouseLeave={displayHideCategory}>
                        SELF
                    </div>
                    <div className='text-shadow InterstateCompressed-Bold' style={{width:'8ch'}} onClick={() => {
                        props.history.push('/culture')
                        onClose();
                    }} onMouseOver={() => { displayCategory('culture') }} onMouseLeave={displayHideCategory} >
                        CULTURE
                    </div>
                    <div className='text-shadow InterstateCompressed-Bold' style={{width:'11ch'}} onClick={() => {
                        props.history.push('/community')
                        onClose();
                    }} onMouseOver={() => { displayCategory('community') }} onMouseLeave={displayHideCategory} >
                        COMMUNITY
                    </div>
                    <div className='text-shadow InterstateCompressed-Bold' style={{width:'11ch'}} onClick={() => {
                        props.history.push('/news-letter')
                        onClose();
                    }} onMouseOver={() => { displayCategory('') }} onMouseLeave={displayHideCategory} >
                        SUBSCRIBE
                    </div>
                </div>
                {
                    category === 'style' ? <Hamstyle onClose={onClose} /> : category === 'self' ? <Hamself onClose={onClose} /> : category === 'culture' ? <Hamculture onClose={onClose} /> : category === 'community' ? <Hamcommunity onClose={onClose} /> : ''
                }
            </div>
        </div>
    )
}
export default (withRouter)(HamShow)
