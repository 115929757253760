import React from 'react';
import Header from '../../components/common/Header2/Header';
import Footer from '../../components/common/Footer/Footer';
import {useEffect} from 'react'

function CommonArticle(props) {
    const data = props.data;
    useEffect(() => {
            window.scrollTo(0, 0);

    }, []);

    const heroData={
        img:"https://gumlet.assettype.com/" +data['story']['hero-image-s3-key'],
        imgTitle:data['story']['hero-image-caption'],
        cat:data['story']['sections'][0]['name'].toUpperCase(),
        title:data['story']['headline'],
        auth:data['story']['author-name']
    }
  return <div>
      <Header/>
      <div className='ptp-5 a5-1-img'>
            <img src={heroData.img} alt="" />
        </div>
        <div className='a5-p1'>
            <div className='a5-p1-h1 InterstateCompressed-Bold'>
                {heroData.title}
            </div>
            <div className='a5-p1-h1-bottom'>
                <div className='a5-p1-h1-auth Biotif-Book mbp-5'>
                    By {heroData.auth}
                </div>
            </div>
            </div>
        <Footer/>
  </div>;
}

export default CommonArticle;
