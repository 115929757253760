import React from "react";
import "./style.css";
import style from '../../assets/images/title_svg_style.svg'
import { Spin } from 'antd';
import { imgBaseURL } from '../../helper/constants';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { subCatPageUrl } from '../../helper/constants'



function StyleSection(props) {
  const resp = props.resp;
  const handleIndividualStory = (a) => {
    console.log('onclick', a['story']['sections'][0]);
    props.history.push(`/${a['story']['slug']}`, a);
  }
  if (resp.length) {
    return (
      <div className="style-main">
        <div className="style-heading InterstateCompressed-Bold">
          <img src={style} alt='style logo' className="cursor-pointer" onClick={() => { props.history.push('/style') }} />
        </div>
        <div className="style-content-main">
          {
            resp.slice(0, 5).map((a, index) => (
              <div className={`style-content-${index + 1} style-content`}>
                <Link to={{
                  pathname: `/${a['story']['slug']}`,
                  state: { story: a }
                }}>
                  <img className="cursor-pointer" src={`${imgBaseURL}${a['story']['hero-image-s3-key']}`} alt="" />
                </Link>
                <Link to={{
                  pathname: subCatPageUrl[a['story']['sections'][0]['slug']].url,
                  state: { key: subCatPageUrl[a['story']['sections'][0]['slug']].key }
                }}>
                  <div className="style-content-cat Biotif-Book cursor-pointer" onClick={() => { handleIndividualStory(a) }}>
                    {a['story']['sections'][0]['name'].toUpperCase()}
                  </div>
                </Link>
                <Link to={{
                  pathname: `/${a['story']['slug']}`,
                  state: { story: a }
                }}>
                  <div className="style-content-title cursor-pointer Biotif-SemiBold" onClick={() => { handleIndividualStory(a) }}>
                    {a['story']['headline']}
                  </div>
                  <div className="style-content-author cursor-pointer Biotif-Book" onClick={() => { handleIndividualStory(a) }}>
                    By {a['story']['author-name']}
                  </div>
                </Link>
              </div>
            ))
          }
        </div>
        <div className='pink-border mtp-5'></div>
        <div>

          <div className='text-section-1 Biotif-Medium cursor-pointer'>
          <Link to={{
                  pathname: subCatPageUrl[resp[5]['story']['sections'][0]['slug']].url,
                  state: { key: subCatPageUrl[resp[5]['story']['sections'][0]['slug']].key }
                }}>
                  <span className='text-section-1 Biotif-Medium cursor-pointer'>
            {resp[5]['story']['sections'][0]['name'].toUpperCase()}
                  </span>
          </Link>
          <Link to={{
                  pathname: `/${resp[5]['story']['slug']}`,
                  state: { story: resp[5] }
                }}>
          <span className='text-section-1 Biotif-Medium cursor-pointer'>
             – By <em> {resp[5]['story']['author-name']}</em>
            </span>
            </Link>
          </div>
          <Link to={{
                  pathname: `/${resp[5]['story']['slug']}`,
                  state: { story: resp[5] }
                }}>
          <div className='description-section-1 Biotif-SemiBold mtp-1 cursor-pointer' onClick={() => { handleIndividualStory(resp[5]) }}>
            {resp[5]['story']['subheadline'].toUpperCase()}
          </div>
          </Link>
          {/* <div className='description-section-1 Biotif-SemiBold mtp-1' dangerouslySetInnerHTML={{ __html: resp[5]['story']['cards']['0']['story-elements']['0']['text']}}>
        </div> */}
        </div>
        <div className='d-flex justify-content Biotif-Regular mtp-3 mbp-5'>
          <button className='read-more-btn' type='submit' onClick={() => { handleIndividualStory(resp[5]) }}>READ MORE →</button>
        </div>
        <div className='pink-border'></div>

        <div style={{ position: "relative" }} className="cursor-pointer">
        <Link to={{
                  pathname: `/${resp[6]['story']['slug']}`,
                  state: { story: resp[6] }
                }}>

          <img width="100%" style={{ maxHeight: '95vh', objectFit: 'cover' }} src={`${imgBaseURL}${resp[6]['story']['hero-image-s3-key']}`} alt='group-photo-image' />
          <div className='image-data'>
            <div className='image-title Biotif-Regular'>
            <Link to={{
                  pathname: subCatPageUrl[resp[6]['story']['sections'][0]['slug']].url,
                  state: { key: subCatPageUrl[resp[6]['story']['sections'][0]['slug']].key }
                }}>
              <span className="image-title Biotif-Regular">
              {resp[6]['story']['sections'][0]['name'].toUpperCase()}
              </span>
              </Link>
               – By <em>{resp[6]['story']['author-name']}</em>
            </div>
            <div className='image-text Biotif-SemiBold cursor-pointer' onClick={() => { handleIndividualStory(resp[6]) }}>
              {resp[6]['story']['headline']}
            </div>
          </div>
          </Link>
        </div>
      </div>
    );
  }
  else {
    return <div></div>
  }
}

export default (withRouter)(StyleSection)