import React, { useEffect, useMemo } from "react";
import Header from "../../components/common/Header2/Header";
import Footer from "../../components/common/Footer/Footer";
import RandomStyle from "./RandomStyle.js";
import { imgBaseURL } from "../../helper/constants";
import { Link } from "react-router-dom";
import { generateSchemaData } from "../../helper/schemaData.js";
import { Helmet } from "react-helmet";

const RandomLayout = (props) => {
  const data = props.data;
  const cards = data["story"]["cards"];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  console.log("inside randon card", cards);
  const heroData = {
    img: imgBaseURL + data["story"]["hero-image-s3-key"],
    imgTitle: data["story"]["hero-image-caption"],
    cat: data["story"]["sections"][0]["name"].toUpperCase(),
    title: data["story"]["headline"],
    auth: data["story"]["author-name"],
    authId: data["story"]["author-id"],
  };

  const metaDataStory = {
    title: heroData.title,
    description: heroData.title,
    keywords: "The Established",
  };
  if ("seo" in data["story"]) {
    console.log("in seo");
    if ("meta-title" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-title"].length > 1) {
        metaDataStory.title = data["story"]["seo"]["meta-title"];
        console.log("in seo meta");
      } else {
        metaDataStory.title = heroData.title;
      }
    }
    if ("meta-description" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-description"].length > 1) {
        metaDataStory.description = data["story"]["seo"]["meta-description"];
      } else {
        metaDataStory.title = heroData.title;
        console.log("else in seo meta");
      }
    }
    if ("meta-keywords" in data["story"]["seo"]) {
      if (data["story"]["seo"]["meta-keywords"].length > 1) {
        metaDataStory.keywords =
          data["story"]["seo"]["meta-keywords"].toString();
      }
    }
  } else {
    metaDataStory.title = heroData.title;
    metaDataStory.description = heroData.title;
  }

  const getSchemaData = useMemo(() => {
    return generateSchemaData(props?.data, metaDataStory);
  }, [props.data]);
  return (
    <div>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
        {props?.data && (
          <script type="application/ld+json">
            {JSON.stringify(getSchemaData)}
          </script>
        )}
      </Helmet>
      <Header />
      <RandomStyle>
        <div className="mtp-5 mbp-5">
          <div className="d-flex justify-content-center">
            <img
              width="50%"
              className="main-hero-img"
              src={heroData.img}
              alt="main-image"
            ></img>
          </div>
          <div className="style-title mbp-2 mtp-2">{heroData.cat}</div>
          <div className="HeldaneDisplay-Regular style-question pbp-2">
            <div className="internal-div HeldaneDisplay-Regular">
              {heroData.title}
            </div>
          </div>
          <Link
            to={{
              pathname: `/author/${heroData.authId}/${heroData.auth
                ?.replace(/ /g, "-")
                .toLowerCase()}`,
              state: { story: data, authId: heroData.authId },
            }}
          >
            <div className="style-author mbp-2 mtp-2"> By {heroData.auth}</div>
          </Link>

          <div className="mlp-30 mrp-30">
            {cards.map((card) =>
              card["story-elements"].map((element) =>
                element.type === "text" && element.subtype === null ? (
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: element.text }}
                    ></div>
                  </>
                ) : element.type === "image" ? (
                  <>
                    <div>
                      <img
                        src={`${imgBaseURL}${element["image-s3-key"]}`}
                        alt="hero image"
                      />
                      <div
                        className="image-title"
                        dangerouslySetInnerHTML={{ __html: element.title }}
                      ></div>
                    </div>
                  </>
                ) : element.subtype === "image-gallery" ? (
                  <div className="image-bundle">
                    {element["story-elements"].map((image, index) => (
                      <div className={`image-single image-${index}`}>
                        <img
                          src={`${imgBaseURL}${image["image-s3-key"]}`}
                          alt=""
                        />
                        <div
                          className="image-title"
                          dangerouslySetInnerHTML={{ __html: image.title }}
                        ></div>
                      </div>
                    ))}{" "}
                  </div>
                ) : (
                  ""
                )
              )
            )}
          </div>
        </div>
      </RandomStyle>
      <Footer />
    </div>
  );
};

export default RandomLayout;
