import React, { useEffect } from "react";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header2/Header";
import PartnerWithUsWrapper from "./style";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SubscribeSticky from "../../components/common/SubscribeSticky/SubscribeSticky";

const PartnerWithUs = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <PartnerWithUsWrapper>
      {/* <Helmet defer={false}>
                <title>Connect with The Established | Partner & Collaborate with The Established</title>
                <meta name="description" content="For more details on how to partner with us, drop us an email on contact@theestablished.com and reach out to a well-informed audience" />
            </Helmet> */}
      {/* Google Tag Manager (gtag.js) */}

      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>
      </Helmet>
      <Header />
      <div className="partner-background">
        <div className="partner-title">PARTNER WITH US</div>
        <div className="partner-description pbp-10 partner-with-us-main">
          <div className="Biotif-Light">
            The Established is a digital platform shaped by and for a community
            of conscious, authentic and well-informed, progressive consumers.
          </div>
          <div className="Biotif-Light">
            Through various mediums, we engage on a real-time basis with diverse
            individuals—coming together with a common vision of riveting,
            relevant and disruptive conversations centred around the worlds of
            fashion, beauty, culture, tech, auto and entertainment.
          </div>
          <div className="Biotif-Light">
            Our partnership solutions reach high-quality responsive audiences
            via
            <div className="Biotif-SemiBold mtp-2">→ newsletter</div>
            <div className="Biotif-SemiBold">
              → branded content through social media
            </div>
            <div className="Biotif-SemiBold">
              → online display advertisements on our website
            </div>
            <div className="Biotif-SemiBold">
              → collaboration opportunities through our intellectual properties
            </div>
          </div>
          <div className="Biotif-Light" style={{ lineHeight: 1.3 }}>
            For more details on how to partner with us, drop us an email on{" "}
            <a
              className="Biotif-SemiBold"
              onClick={() =>
                (window.location = "mailto:contact@theestablished.com")
              }
            >
              contact@theestablished.com
            </a>{" "}
            and reach out to a well-informed audience
          </div>
        </div>
      </div>
      <SubscribeSticky />
      <Footer />
    </PartnerWithUsWrapper>
  );
};

export default PartnerWithUs;
