import React, { Component } from "react";
import { Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// const menu = (
//   // <Menu>
//   //   <Menu.Item>
//   //     <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
//   //       1st menu item
//   //     </a>
//   //   </Menu.Item>
//   //   <Menu.Item >
//   //     <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
//   //       2nd menu item (disabled)
//   //     </a>
//   //   </Menu.Item>
//   // </Menu>
// );
const LeftMenu = (props) => {
  return (
    // <Menu mode="horizontal">
    <div className="nav-sub-menu">
      <div className="nav-options">
        <div className="nav-sub-menu nav-items">
          <a
            className="ant-dropdown-link cursor-pointer Biotif-Regular"
            onClick={() => props.history.push("/style")}
          >
            STYLE ↓
          </a>
          <div className="nav-options-sub-menu">
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() => props.history.push("/style/fashion", { key: "1" })}
            >
              FASHION
            </div>{" "}
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() =>
                props.history.push("/style/sneakers", { key: "2" })
              }
            >
              SNEAKERS
            </div>
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() => props.history.push("/style/watches", { key: "3" })}
            >
              WATCHES
            </div>
          </div>
        </div>
        <div className="nav-sub-menu nav-items">
          <a
            className="ant-dropdown-link cursor-pointer Biotif-Regular"
            onClick={() => props.history.push("/self")}
          >
            SELF ↓
          </a>
          <div className="nav-options-sub-menu">
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() => props.history.push("/self/beauty", { key: "1" })}
            >
              BEAUTY & GROOMING
            </div>{" "}
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() =>
                props.history.push("/self/health-wellness", { key: "2" })
              }
            >
              HEALTH & WELLNESS
            </div>
          </div>
        </div>
        <div className="nav-sub-menu nav-items">
          <a
            className="ant-dropdown-link cursor-pointer Biotif-Regular"
            onClick={() => props.history.push("/culture")}
          >
            CULTURE ↓
          </a>
          <div className="nav-options-sub-menu">
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() => props.history.push("/culture/auto", { key: "1" })}
            >
              AUTO
            </div>{" "}
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() =>
                props.history.push("/culture/entertainment", { key: "2" })
              }
            >
              ENTERTAINMENT
            </div>
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() =>
                props.history.push("/culture/gaming-tech", { key: "3" })
              }
            >
              GAMING & TECH
            </div>
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() =>
                props.history.push("/culture/living", { key: "4" })
              }
            >
              LIVING
            </div>
          </div>
        </div>
        <div className="nav-sub-menu nav-items">
          <a
            className="ant-dropdown-link cursor-pointer Biotif-Regular"
            onClick={() => props.history.push("/community")}
          >
            COMMUNITY ↓
          </a>
          <div className="nav-options-sub-menu">
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() =>
                props.history.push("/community/business", { key: "1" })
              }
            >
              BUSINESS
            </div>{" "}
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() =>
                props.history.push("/community/conscious-living", { key: "2" })
              }
            >
              CONSCIOUS LIVING
            </div>
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() =>
                props.history.push("/community/identity", { key: "3" })
              }
            >
              IDENTITY
            </div>
            <div
              className="cursor-pointer Biotif-Regular nav-sub-items"
              onClick={() =>
                props.history.push("/community/viewpoint", { key: "4" })
              }
            >
              VIEWPOINT
            </div>
          </div>
        </div>
        <div className="nav-sub-menu nav-items">
          <a
            className="ant-dropdown-link cursor-pointer Biotif-Regular"
            onClick={() => props.history.push("/news-letter")}
          >
            SUBSCRIBE ↓
          </a>
        </div>
      </div>
      {/* <div className="nav-options">
        <div className="nav-options-sub-menu">
          <div>Fashion</div> <div>Fashion</div>
          <div>Fashion</div>
        </div>
        <div className="nav-options-sub-menu">
          <div>Fashion</div> <div>Fashion</div>
          <div>Fashion</div>
        </div>
        <div className="nav-options-sub-menu">
          <div>Fashion</div> <div>Fashion</div>
          <div>Fashion</div>
        </div>
        <div className="nav-options-sub-menu">
          <div>Fashion</div> <div>Fashion</div>
          <div>Fashion</div>
        </div>
        <div className="nav-options-sub-menu">
          <div>Fashion</div> <div>Fashion</div>
          <div>Fashion</div>
        </div>
      </div> */}
    </div>
  );
};
export default withRouter(LeftMenu);
