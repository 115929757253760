import React from 'react';
import { imgBaseURL } from '../../../helper/constants';

const EstdFullFormat1Card1 = (props) => {
    const { cards } = props;
    console.log('cards', cards, props)
    const storyElement = cards['story-elements'];
    if (storyElement) {
        return <div className='a4-p1'>
            {
                storyElement.map((element) => (
                    element.type === 'text' ?
                        <div className='Heldane-Text-Regular a4-p1-p1' dangerouslySetInnerHTML={{ __html: element.text }}></div>:
                        element.subtype === 'image-gallery' ? <div className='a4-img'>
                        {
                            element['story-elements'].map(imageData => (

                                <div className='a4-img-1'>
                                    <img src={`${imgBaseURL}${imageData['image-s3-key']}`}></img>
                                    <div className>
                                    <div className='a4-img-caption' dangerouslySetInnerHTML={{ __html: imageData['title'] }}></div>
                                    <div className='a4-img-caption' dangerouslySetInnerHTML={{ __html: imageData['image-attribution'] }}></div>
                                    </div>
                                    </div>
                            ))
                        }
                    </div> : ''
                ))

            }
        </div>;
    }
    else {
        return <div></div>
    }
};

export default EstdFullFormat1Card1;
