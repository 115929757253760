import React from 'react';
import { imgBaseURL } from '../../../helper/constants';

const EstdFullFormat2card1 = (props) => {
    const { cards } = props;
    console.log('cards', cards, props)
    const storyElement = cards['story-elements'];
    if (storyElement) {
        return <div className='a5-p1'>
            {
                storyElement.map((element) => (
                    element.type === 'text' ?
                    <div className='a5-p1-p1' dangerouslySetInnerHTML={{ __html: element.text}}>
                </div>:
                        element.type === 'image' ? <div><div className='a6-img'>
                        <div className='a6-img-1'>
                            <img src={`https://gumlet.assettype.com/${element['image-s3-key']}`} alt="" />
                            <div className='a4-img-caption' dangerouslySetInnerHTML={{ __html: element['title'] }}></div>
                        </div>
                        <div className='a6-img-1'>
                            <img  src={`https://gumlet.assettype.com/${element['image-s3-key']}`} alt="" />
                            <div className='a4-img-caption' dangerouslySetInnerHTML={{ __html: element['title'] }}></div>
                        </div>

                    </div>
                    </div> : element.subtype === 'image-gallery' ? <div className='a6-img mbp-2'>
                        {
                            element['story-elements'].map(imageData => (
                                <div className='a6-img-1'>
                                    <img className='img-h-90' src={`${imgBaseURL}${imageData['image-s3-key']}`}/>
                                    <div className='a4-img-caption' dangerouslySetInnerHTML={{ __html: imageData['title'] }}></div>
                                </div>
                            ))
                        }
                    </div>: ''
                ))

            }
        </div>;
    }
    else {
        return <div></div>
    }
};

export default EstdFullFormat2card1;
